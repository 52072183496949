import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { APIURL } from '../global/constant';
import * as moment from 'moment'

@Injectable({
  providedIn: 'root',
})
export class SectionTypeService {
  constructor(private commonService: CommonService) { }

  getSectionType(payload) {
    let param = this.commonService.getParamsFromObj(payload);
    return this.commonService.getWithTokenWithParams(APIURL.sectionType, param);
  }

  orderTimeDuration(orderDateTime) {
    var now = moment(new Date()).format('DD/MM/yyyy HH:mm:ss');
    var then = moment(orderDateTime).format('DD/MM/yyyy HH:mm:ss');

    var ms = moment(now, 'DD/MM/YYYY HH:mm:ss').diff(
      moment(then, 'DD/MM/YYYY HH:mm:ss')
    );
    var d: any = moment.duration(ms);

    let data: any = {
      days: d._data.days,
      hours: d._data.hours,
      milliseconds: d._data.milliseconds,
      minutes: d._data.minutes,
      months: d._data.months,
      seconds: d._data.seconds,
      years: d._data.years,
    };

    if (data.hours != 0 && data.minutes != 0) {
      return `${data.hours} H ${data.minutes} M`;
    } else if (data.hours != 0 && data.minutes == 0 && data.days == 0 && data.months == 0) {
      return `${data.hours} Hours`;
    } else if (data.hours == 0 && data.minutes != 0 && data.days == 0 && data.months == 0) {
      return `${data.minutes} Minutes`;
    } else if (data.months != 0) {
      return `${data.months} Month`;
    } else if (data.days != 0) {
      return `${data.days} Days`;
    } else {
      return `0 Minute`;
    }
  }
}
