import { Injectable } from '@angular/core';
import { APIURL, LOCAL_STORAGE_KEYS } from '../global/constant';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  restaurantId: any;
  constructor(private commonService: CommonService) {
    this.restaurantId = localStorage.getItem('restaurantId');
  }

  getDashboardCounter() {
    return this.commonService.get(`${APIURL.dashboardCounters}/${this.restaurantId}`);
  }
}
