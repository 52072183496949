import { Injectable } from '@angular/core';
import { IndexDbStorageService } from './localstorage.service';
import { SharedService } from './shared.service';
import { LOCAL_STORAGE_KEYS } from '../global/constant';
import * as _ from 'lodash';
import * as moment from 'moment';
import { fromEvent, merge, of, Subscription } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { OrderCheckoutService } from './order-checkout.service';

@Injectable({
  providedIn: 'root',
})
export class OfflineOrderDataService {
  runningOrderList: any[] = [];
  SaveCustomerData: any[] = [];
  checkoutOrderList: any[] = [];
  defaultPaymentList: any;
  networkStatus: any;
  networkStatus$: Subscription = Subscription.EMPTY;
  constructor(
    private indexDbService: IndexDbStorageService,
    private sharedService: SharedService,
    private checkoutOrderService: OrderCheckoutService
  ) {
    this.getDataFromIndexedDB();
    this.getCheckoutDataOrderList();
    this.getDefaultPayment();
  }

  async saveCustomerDataInIndexDb(ObjCustomerData) {
    const Customerresult: any = await this.indexDbService.getDataFromIndexedDB(
      LOCAL_STORAGE_KEYS.CUSTOMER_REPORT_LIST
    );
    if (Customerresult) {
      Customerresult.push(ObjCustomerData);
      await this.indexDbService.setDataInIndexedDBWithAwait(
        LOCAL_STORAGE_KEYS.CUSTOMER_REPORT_LIST,
        Customerresult
      );
    } else {
      await this.indexDbService.setDataInIndexedDBWithAwait(
        LOCAL_STORAGE_KEYS.CUSTOMER_REPORT_LIST,
        [ObjCustomerData]
      );
    }
  }

  async getRestaurantDetailsFromIndexDbReturn() {
    let restaurantDetails = await this.indexDbService.getRestaurantAuthData(
      LOCAL_STORAGE_KEYS.RESTAURANT_DETAILS
    );
    return restaurantDetails;
  }

  async savePaymentDataInIndexDb(ObjPaymentData) {
    const paymentresult: any = await this.indexDbService.getDataFromIndexedDB(
      LOCAL_STORAGE_KEYS.PAYMENT_REPORT
    );
    if (paymentresult) {
      paymentresult.push(ObjPaymentData);
      await this.indexDbService.setDataInIndexedDBWithAwait(
        LOCAL_STORAGE_KEYS.PAYMENT_REPORT,
        paymentresult
      );
    } else {
      await this.indexDbService.setDataInIndexedDBWithAwait(
        LOCAL_STORAGE_KEYS.PAYMENT_REPORT,
        [ObjPaymentData]
      );
    }
  }

  // async getRunningCustomerData(Id) {
  //   const CustomerDetails = await this.SaveCustomerData.find((x) => {
  //     return x.Id == Id;
  //   });
  //   return CustomerDetails;
  // }

  // set Running Order (kot,withoutKot,proforma) Data In IndexDb Localstorage
  async saveRunningOrderInIndexDb(objOrderData) {
    const result: any = await this.indexDbService.getDataFromIndexedDB(
      LOCAL_STORAGE_KEYS.RUNNUNG_ORDER_DATA
    );
    if (result) {
      let isExistOrder = await this.getRunningOrderDetails(
        objOrderData.tableId
      );
      if (isExistOrder == undefined) {
        result.push(objOrderData);
        await this.indexDbService.setDataInIndexedDBWithAwait(
          LOCAL_STORAGE_KEYS.RUNNUNG_ORDER_DATA,
          result
        );
      } else {
        result[result.findIndex((x) => x.id == objOrderData.id)] = objOrderData;
        await this.indexDbService.setDataInIndexedDBWithAwait(
          LOCAL_STORAGE_KEYS.RUNNUNG_ORDER_DATA,
          result
        );
      }
    } else {
      await this.indexDbService.setDataInIndexedDBWithAwait(
        LOCAL_STORAGE_KEYS.RUNNUNG_ORDER_DATA,
        [objOrderData]
      );
    }
    await this.getDataFromIndexedDB();
    this.sharedService.gotoPage('/pos');
  }

  // Get Running Orders From IndexDb LocalStorage
  async getDataFromIndexedDB() {
    const res: any = await this.indexDbService.getDataFromIndexedDB(
      LOCAL_STORAGE_KEYS.RUNNUNG_ORDER_DATA
    );

    if (res) {
      this.runningOrderList = _.cloneDeep(res);
      // this.indexDbService.getRefreshDataFromIndexDb.next(true);
    } else {
      this.runningOrderList = [];
    }
    return this.runningOrderList;
  }

  async getBillDetails() {
    const res: any = await this.indexDbService.getDataFromIndexedDB(
      LOCAL_STORAGE_KEYS.BILL_DETAILS
    );
    return res;
  }

  async getKotNumberDetails() {
    const res: any = await this.indexDbService.getDataFromIndexedDB(
      LOCAL_STORAGE_KEYS.KOT_NUMBER
    );
    return res;
  }

  async setKotNumber(kotDetails) {
    const res: any = await this.indexDbService.setDataInIndexedDBWithAwait(
      LOCAL_STORAGE_KEYS.KOT_NUMBER, kotDetails
    );
  }

  async setBillDetails(bill) {
    const res: any = await this.indexDbService.setDataInIndexedDBWithAwait(
      LOCAL_STORAGE_KEYS.BILL_DETAILS, bill
    );
  }

  // get Running Orders Details
  async getRunningOrderDetails(tableId) {
    const orderDetails = await this.runningOrderList.find((x) => {
      return x.tableId == tableId;
    });
    return orderDetails;
  }

  // delete Running Order From IndexDb LocalStorage (Note:- It's delete only without kot data)
  async deleteRunningOrderData(tableId) {
    const data = _.remove(this.runningOrderList, (x) => {
      return x.tableId == tableId;
    });

    if (data) {
      await this.indexDbService.setDataInIndexedDBWithAwait(
        LOCAL_STORAGE_KEYS.RUNNUNG_ORDER_DATA,
        this.runningOrderList
      );
      await this.getDataFromIndexedDB();
    }
  }

  async deleteCheckoutOrderData(tableId) {
    const data = _.remove(this.checkoutOrderList, (x) => {
      return x.id == tableId;
    });

    if (data) {
      await this.indexDbService.setDataInIndexedDBWithAwait(
        LOCAL_STORAGE_KEYS.CHECKOUT_ORDER_DATA,
        this.checkoutOrderList
      );
      // await this.getDataFromIndexedDB();
    }
  }

  // save checkout order data in IndexDb;
  async saveCheckoutOrderDataIndexDb(orderDetails) {
    delete orderDetails.restaurantCustomerDetails;
    if (this.checkoutOrderList.length != 0) {
      this.checkoutOrderList.push(orderDetails);
      await this.indexDbService.setDataInIndexedDBWithAwait(
        LOCAL_STORAGE_KEYS.CHECKOUT_ORDER_DATA,
        this.checkoutOrderList
      );
    } else {
      await this.indexDbService.setDataInIndexedDBWithAwait(
        LOCAL_STORAGE_KEYS.CHECKOUT_ORDER_DATA,
        [orderDetails]
      );
    }

    this.deleteRunningOrderData(orderDetails.tableId);

    this.sharedService.gotoPage('/pos');
  }

  async getCheckoutDataOrderList() {
    const res: any = await this.indexDbService.getDataFromIndexedDB(
      LOCAL_STORAGE_KEYS.CHECKOUT_ORDER_DATA
    );

    if (res) {
      this.checkoutOrderList = res;
    } else {
      this.checkoutOrderList = [];
    }
    return this.checkoutOrderList;
  }

  async saveAsyncRunningOrderInIndexDb(objOrderData) {
    await this.indexDbService.setDataInIndexedDBWithAwait(
      LOCAL_STORAGE_KEYS.RUNNUNG_ORDER_DATA,
      objOrderData
    );
  }

  dateCompare(d1, d2) {
    const date1 = new Date(d1);
    const date2 = new Date(d2);

    if (date1 > date2) {
      console.log(`${d1} is greater than ${d2}`);
    } else if (date1 < date2) {
      console.log(`${d2} is greater than ${d1}`);
    } else {
      console.log(`Both dates are equal`);
    }
  }

  async savedUnsavedOrders(objOrderData) {
    await this.indexDbService.setDataInIndexedDBWithAwait(
      LOCAL_STORAGE_KEYS.CHECKOUT_ORDER_DATA,
      objOrderData
    );
  }

  async saveAsyncCheckoutOrderInIndexDb(objOrderData) {
    if (!objOrderData) return;
    const result: any = await this.indexDbService.getDataFromIndexedDB(
      LOCAL_STORAGE_KEYS.CHECKOUT_ORDER_DATA
    );
    if (result) {
      let secondArraySameData = objOrderData?.filter(
        (o1) => !result.some((o2) => o1.id === o2.id)
      );
      const dataData = result.concat(secondArraySameData);
      dataData.forEach((element, index) => {
        objOrderData.map((res) => {
          if (res.id === element.id) {
            dataData[index] = res;
          }
        });
      });
      await this.indexDbService.setDataInIndexedDBWithAwait(
        LOCAL_STORAGE_KEYS.CHECKOUT_ORDER_DATA,
        dataData
      );
    } else {
      await this.indexDbService.setDataInIndexedDBWithAwait(
        LOCAL_STORAGE_KEYS.CHECKOUT_ORDER_DATA,
        objOrderData
      );
    }
    this.getDataFromIndexedDB();
  }

  getDefaultPayment() {
    this.indexDbService
      .getDataFromIndexedDB(LOCAL_STORAGE_KEYS.PAYMENT_TYPE)
      .then((res: any) => {
        if (res) {
          this.defaultPaymentList = res;
        }
      })
      .catch((err) => {
        if (err) {
          this.sharedService.errorToast(err);
        }
      });
  }

  //Payment Type Mode Cash Box
  async cashBoxAmount(checkoutPayment) {
    let checkoutPay = checkoutPayment;
    let getDataFromSWD: any = await this.indexDbService.getDataFromIndexedDB(
      LOCAL_STORAGE_KEYS.PAYMENT_DETAILS_CASHBOX
    );
    checkoutPay.forEach(async (element: any) => {
      getDataFromSWD == null
        ? (getDataFromSWD = [])
        : (getDataFromSWD = getDataFromSWD);
      let paymentCash = {
        date: moment(new Date()).format('DD/MM/YYYY'),
        time: moment(new Date()).format('hh:mm A'),
        type: element.type,
        narration: element.narration,
      };
      if (getDataFromSWD.length == 0) {
        Object.assign(paymentCash, {
          amount: element.amount,
          currentBalance:
            element.type == 'withdraw' ? -element.amount : element.amount,
        });
        getDataFromSWD.push(paymentCash);
      } else {
        if (element.type == 'setNewBalance' || element.type == 'withdraw') {
          Object.assign(paymentCash, {
            amount:
              element.narration == 'withdrawCurrentAmount'
                ? parseInt(
                    getDataFromSWD[getDataFromSWD.length - 1].currentBalance
                  )
                : element.amount,
            currentBalance:
              element.type == 'withdraw'
                ? parseInt(
                    getDataFromSWD[getDataFromSWD.length - 1].currentBalance
                  ) - parseInt(element.amount)
                : element.amount,
          });
          getDataFromSWD.push(paymentCash);
        } else {
          Object.assign(paymentCash, {
            amount: element.amount,
            currentBalance:
              parseInt(
                getDataFromSWD[getDataFromSWD.length - 1].currentBalance
              ) + parseInt(element.amount),
          });
          getDataFromSWD.push(paymentCash);
        }
      }
      await this.indexDbService.setDataInIndexedDBWithAwait(
        LOCAL_STORAGE_KEYS.PAYMENT_DETAILS_CASHBOX,
        getDataFromSWD
      );
    });
  }

  getTotalCash(log, oldLogsData) {
    if (log.type === 'Cash' || log.type === 'Deposit') {
    }
  }
  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe((status) => {
        this.networkStatus = status;
      });

    return this.networkStatus;
  }

  async saveCheckOutOrder() {
    let getCheckOutDataFromIndexDB = await this.getCheckoutDataOrderList();
    // if (getCheckOutDataFromIndexDB.length != 0) {
    //   getCheckOutDataFromIndexDB.forEach((order, index) => {
    //     Object.assign(getCheckOutDataFromIndexDB[index], {
    //       posOrderId: order.id,
    //     });
    //     delete getCheckOutDataFromIndexDB[index].id;
    //   });
    //   this.socketIoService.syncCheckOutOrderData(getCheckOutDataFromIndexDB);
    // }
    return this.checkoutOrderService.posOrderCheckOut(
      getCheckOutDataFromIndexDB
    );
  }

  async saveCheckOutData() {
    this.getCheckoutDataOrderList();
    if (this.checkoutOrderList.length) {
      (await this.saveCheckOutOrder())
        .pipe(
          finalize(() => {
            this.sharedService.hideSpinner();
          })
        )
        .subscribe(
          (res: any) => {
            if (res.statusCode == 502) {
              this.deleteCheckoutOrderData(res.data[0].value);
              this.saveCheckOutData();
              return;
            }
            if (res.statusCode == 200) {
              this.sharedService.successToast('Pos Order Successfully Saved');
              res.data.forEach((element: any) => {
                this.deleteCheckoutOrderData(element.id);
              });
            }
          },
          (err) => {
            this.sharedService.errorToast(err.message);
          }
        );
    }
  }
}
