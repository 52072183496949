import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { APIURL } from '../global/constant';

@Injectable({
  providedIn: 'root',
})
export class CounterTypeService {
  constructor(private commonService: CommonService) {}

  getCounterType() {
    return this.commonService.getWithToken(APIURL.counterType);
  }
}
