import { Injectable } from '@angular/core';
import { PrintService, UsbDriver } from 'ng-thermal-print';
import { commands, LOCAL_STORAGE_KEYS } from '../global/constant';
import { IRestaurant } from '../shared/models/restaurant.model';
import { IndexDbStorageService } from './localstorage.service';
import { SharedService } from './shared.service';
import { TitleCasePipe } from '@angular/common'
import * as moment from 'moment';
import * as _ from 'lodash';
@Injectable({
  providedIn: 'root',
})
export class PrinterSetupService {
  public status: boolean = false;
  titlecasePipe = new TitleCasePipe();
  usbPrintDriver: UsbDriver;
  usbPrintDriverLocal: UsbDriver;
  device: USBDevice;
  // vendorId: any = 1110;
  // productId: any = 2056;
  vendorId: any;
  productId: any;
  restaurantDetails: any;
  isPrinterConnected: boolean = false;
  connectedPrinterName: any;
  selectedPrinterDetails: any = {};
  selectedPrinter: any;
  printerDetails: any;
  _navigator: any;
  printCharacteristic: any;
  message: any;
  deviceInfo: any;

  constructor(
    private printService: PrintService,
    public sharedService: SharedService,
    private indexDbService: IndexDbStorageService
  ) {
    this._navigator = navigator;
    this.getRestaurantDetailsFromIndexDb();
    setTimeout(() => {
      this.connectDevise();
    }, 500);
    let printerDetails: any = JSON.parse(
      localStorage.getItem('printerDetails')
    );
    if (printerDetails) {
      this.vendorId = printerDetails?.vendorId;
      this.productId = printerDetails?.productId;
    }
  }

  connectDevise() {
    if (localStorage.getItem('printerDetails')) {
      let { productId, vendorId } = JSON.parse(
        localStorage.getItem('printerDetails')
      );
      this.usbPrintDriver = new UsbDriver(vendorId, productId);
      this.printService.setDriver(this.usbPrintDriver, 'StarPRNT');
    }
  }

  requestUsb() {
    this.usbPrintDriver.requestUsb().subscribe((result) => {
      this.connectedPrinterName = result.productName;
      let printerDetails = {
        productId: this.usbPrintDriver['productId'],
        vendorId: this.usbPrintDriver['vendorId'],
      };
      localStorage.setItem('printerDetails', JSON.stringify(printerDetails));
      this.printService.setDriver(this.usbPrintDriver, 'ESC/POS');
    });
  }

  getBluetoothPrinter() {
    this._navigator.bluetooth
      .requestDevice({
        acceptAllDevices: true,
        optionalServices: [
          '000018f0-0000-1000-8000-00805f9b34fb',
          'device_information',
        ],
      })
      .then((device: any) => {
        this.deviceInfo = device;
        return device.gatt.connect();
      })
      .then((server: any) => {
        let payload = {
          connected: server.connected,
          id: server.device.id,
          name: server.device.name,
        };
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.BLUETOOTH_PRINTER_DETAILS,
          JSON.stringify(payload)
        );
        return server.getPrimaryService('000018f0-0000-1000-8000-00805f9b34fb');
      })
      .then((service: any) => {
        return service.getCharacteristic(
          '00002af1-0000-1000-8000-00805f9b34fb'
        );
      })
      .then((characteristic: any) => {
        this.printCharacteristic = characteristic;
      });
  }

  //print the data
  writeOut(data, start) {
    if (start >= data.length) return;

    this.printCharacteristic
      .writeValue(new TextEncoder().encode(data.substring(start, start + 20)))
      .then(() => {
        this.writeOut(data, start + 20);
      });
  }

  initialPrinter() {
    navigator.usb.getDevices().then((devices) => {
      this.device = devices.find((device) => {
        return (
          device.vendorId === this.vendorId &&
          device.productId === this.productId
        );
      });
      return this.device?.open();
    });
    this.usbPrintDriver = new UsbDriver();
    this.printService.isConnected.subscribe((result) => {
      this.status = result;
      if (result) {
        console.log('Connected to printer!!!');
        if (!this.isPrinterConnected) {
          this.connectDevise();
          this.isPrinterConnected = true;
        }
      } else {
        console.log('Not connected to printer.');
        this.isPrinterConnected = false;
      }
    });
  }

  async getRestaurantDetailsFromIndexDb() {
    this.restaurantDetails = await this.indexDbService.getRestaurantAuthData(
      LOCAL_STORAGE_KEYS.RESTAURANT_DETAILS
    );
  }

  //2 Inch Kot Printer Service
  async printOrderKotReceiptFor2inch(orderList, tableDetails, counterType, restaurantDetails) {
    const kotData = _.filter(orderList, (x) => {
      return !x.isKotPrinted;
    });

    if (kotData.length == 0) {
      return;
    }

    let receipt = '';
    receipt += commands.HARDWARE.HW_INIT;
    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_CT;
    receipt += restaurantDetails?.restaurantName;
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    receipt += commands.HORIZONTAL_LINE.HR1_58MM;
    receipt += commands.EOL;
    receipt += 'KOT PRINT';
    receipt += commands.EOL;
    receipt += counterType;
    receipt += commands.EOL;
    receipt +=
      'Table No : ' + `${tableDetails.shortName}-${tableDetails.hotkey}`;
    receipt += commands.EOL;
    receipt += `KOT No : ${tableDetails?.kotNumber}`;
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR1_58MM;
    receipt += commands.EOL;
    receipt += 'Item                         Qty';
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR1_58MM;
    receipt += commands.EOL;
    // Dynamic Order List Manage
    orderList.forEach((element) => {
      if (!element.isKotPrinted) {
        let productInstr = '';
        productInstr = element.itemName;
        element.itemName = element.instruction
          ? `${element.itemName} (${element.instruction})`
          : element.itemName;
        let itemLength: number = 28 - element.itemName.length;
        element.quantity = element.quantity.toString();
        if (element.itemName.length > 28) {
          let itemsName = element.itemName.match(/.{1,27}/g);
          itemsName.forEach((item, index) => {
            let itemNameLength = 28 - item.length;
            if (index == 0) {
              receipt += `${
                item +
                Array(itemNameLength > 0 ? itemNameLength : 0)
                  .fill('\x20')
                  .join('')
              }${
                element.quantity.length == 1
                  ? '  ' + element.quantity
                  : element.quantity.length == 2
                  ? ' ' + element.quantity
                  : element.quantity
              }`;
            } else {
              receipt += commands.EOL;
              receipt += `${
                item.trim() +
                Array(itemNameLength > 0 ? itemNameLength : 0)
                  .fill('\x20')
                  .join('')
              }`;
            }
          });
        } else {
          // receipt += `${itemLength > 0? element.name + Array(itemLength >0?itemLength:0).fill('\x20').join(''):element.name.substring(0, 14) + ' -' +commands.EOL + element.name.substring(14,element.name.length) }${element.qty.length == 1 ? '  ' + element.qty : element.qty.length == 2 ? ' ' + element.qty : element.qty}  ${element.price.length == 2 ? '   ' + element.price : element.price.length == 3 ? '  ' + element.price : ' ' + element.price} ${element.amt.length == 2 ? '   ' + element.amt : element.amt.length == 3 ? '  ' + element.amt : ' ' + element.amt}`;
          receipt += `${
            element.itemName +
            Array(itemLength > 0 ? itemLength : 0)
              .fill('\x20')
              .join('')
          }${
            element.quantity.length == 1
              ? '  ' + element.quantity
              : element.quantity.length == 2
              ? ' ' + element.quantity
              : element.quantity
          }`;
        }

        receipt += commands.EOL;
        element.itemName = productInstr;
      }
    });
    receipt += commands.HORIZONTAL_LINE.HR1_58MM;
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_RT;
    receipt += moment(new Date()).format('DD/MM/YYYY hh:mm A');
    receipt += commands.EOL;
    receipt += commands.EOL;
    let selectedPrinter = localStorage.getItem(
      LOCAL_STORAGE_KEYS.BLUETOOTH_PRINTER_DETAILS
    );
    if (selectedPrinter) {
      receipt += commands.EOL;
      receipt += commands.EOL;
      receipt += commands.EOL;
    }

    let printerDetail = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.PRINTER_SETTING_DETAILS)
    );
    if (selectedPrinter) {
      this.writeOut(receipt, 0);
    } else if (printerDetail) {
      this.flushReceipt(receipt);
    } else {
      this.sharedService.errorToast('please select a printer first!');
    }
  }

  //3 Inch Kot Printer Service
  async printOrderKotReceiptFor3inch(orderList, tableDetails, counterType, restaurantDetails) {
    const kotData = _.filter(orderList, (x) => {
      return !x.isKotPrinted;
    });

    if (kotData.length == 0) {
      return;
    }

    let receipt = '';
    receipt += commands.HARDWARE.HW_INIT;
    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_CT;
    receipt += restaurantDetails?.restaurantName;
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    receipt += commands.HORIZONTAL_LINE.HR1_80MM;
    receipt += commands.EOL;
    receipt += 'KOT PRINT';
    receipt += commands.EOL;
    receipt += counterType;
    receipt += commands.EOL;
    receipt +=
      'Table No : ' + `${tableDetails.shortName}-${tableDetails.hotkey}`;
    receipt += commands.EOL;
    receipt += `KOT No : ${tableDetails?.kotNumber}`;
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR1_80MM;
    receipt += commands.EOL;
    receipt += 'Item                                        Qty';
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR1_80MM;
    receipt += commands.EOL;
    // Dynamic Order List Manage
    orderList.forEach((element) => {
      if (!element.isKotPrinted) {
        let productInstr = '';
        productInstr = element.itemName;
        element.itemName = element.instruction
          ? `${element.itemName} (${element.instruction})`
          : element.itemName;
        let itemLength: number = 42 - element.itemName.length;
        element.quantity = element.quantity.toString();
        if (element.itemName.length > 42) {
          let itemsName = element.itemName.match(/.{1,27}/g);
          itemsName.forEach((item, index) => {
            let itemNameLength = 42 - item.length;
            if (index == 0) {
              receipt += `${
                item +
                Array(itemNameLength > 0 ? itemNameLength : 0)
                  .fill('\x20')
                  .join('')
              }${
                element.quantity.length == 1
                  ? '   ' + element.quantity
                  : element.quantity.length == 2
                  ? '  ' + element.quantity
                  : element.quantity.length == 3
                  ? ' ' + element.quantity
                  : element.quantity
              }`;
            } else {
              receipt += commands.EOL;
              receipt += `${
                item.trim() +
                Array(itemNameLength > 0 ? itemNameLength : 0)
                  .fill('\x20')
                  .join('')
              }`;
            }
          });
        } else {
          // receipt += `${itemLength > 0? element.name + Array(itemLength >0?itemLength:0).fill('\x20').join(''):element.name.substring(0, 14) + ' -' +commands.EOL + element.name.substring(14,element.name.length) }${element.quantity.length == 1 ? '  ' + element.quantity : element.quantity.length == 2 ? ' ' + element.quantity : element.quantity}  ${element.price.length == 2 ? '   ' + element.price : element.price.length == 3 ? '  ' + element.price : ' ' + element.price} ${element.amt.length == 2 ? '   ' + element.amt : element.amt.length == 3 ? '  ' + element.amt : ' ' + element.amt}`;
          receipt += `${
            element.itemName +
            Array(itemLength > 0 ? itemLength : 0)
              .fill('\x20')
              .join('')
          }${
            element.quantity.length == 1
              ? '   ' + element.quantity
              : element.quantity.length == 2
              ? '  ' + element.quantity
              : element.quantity.length == 3
              ? ' ' + element.quantity
              : element.quantity
          }`;
        }

        receipt += commands.EOL;
        element.itemName = productInstr;
      }
    });
    receipt += commands.HORIZONTAL_LINE.HR1_80MM;
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_RT;
    receipt += moment(new Date()).format('DD/MM/YYYY hh:mm A');
    receipt += commands.EOL;
    receipt += commands.EOL;

    let selectedPrinter = localStorage.getItem(
      LOCAL_STORAGE_KEYS.BLUETOOTH_PRINTER_DETAILS
    );
    if (selectedPrinter) {
      receipt += commands.EOL;
      receipt += commands.EOL;
      receipt += commands.EOL;
    }
    let printerDetail = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.PRINTER_SETTING_DETAILS)
    );
    if (selectedPrinter) {
      this.writeOut(receipt, 0);
    } else if (printerDetail) {
      this.flushReceipt(receipt);
    } else {
      this.sharedService.errorToast('please select a printer first!');
    }
  }

  //2 Inch Printer Service
  async printOrderReceiptFor2inch(
    orderList,
    orderDetails,
    posOrder,
    counterType
  ) {
    await this.getRestaurantDetailsFromIndexDb();
    let orderItems = orderList;
    let totalGst = orderItems.map((x) => x.vat.gst);
    let totalVat = orderItems.map((x) => x.vat.vat);
    let cgstAmount = totalGst.reduce(
      (a, b) => parseFloat(a) + parseFloat(b),
      0
    );
    let vatAmount: any = totalVat.reduce(
      (a, b) => parseFloat(a) + parseFloat(b),
      0
    );
    let cgst = cgstAmount / 2;
    let sgst = cgstAmount / 2;
    let vat = vatAmount;
    let grandTotal =
      parseFloat(posOrder.subTotal) + parseFloat(cgstAmount) + parseFloat(vat);
    grandTotal = grandTotal - parseFloat(posOrder.billDis);
    let sum = 0;
    sum = orderList.reduce((accumulator, object) => {
      return accumulator + object.totalDisAmount;
    }, 0);

    let paymentMode: any[] = this.sharedService.convertStringToJson(
      posOrder.paymentDetails
    );

    let tableName: any;
    let orderDateTime: any;
    if (orderDetails?.PosTable) {
      tableName =
        orderDetails?.PosTable.shortName.toUpperCase() +
        '-' +
        orderDetails?.PosTable?.hotkey;

      Object.assign(posOrder, { tableName: tableName });
      orderDateTime = `${posOrder.orderDate} ${posOrder.orderTime}`;
    } else {
      orderDateTime = moment(new Date()).format('DD/MM/YYYY hh:mm A');
    }

    let receipt = '';
    receipt += commands.HARDWARE.HW_INIT;
    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_CT;
    receipt += this.restaurantDetails?.restaurantName;
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    receipt += this.restaurantDetails?.restaurantAddress;
    receipt += commands.EOL;
    receipt += `${'Mo - '+'+91 ' + this.restaurantDetails?.mobileNumber}${
      this.restaurantDetails?.alternativeMobileNumber
        ? `/${this.restaurantDetails?.alternativeMobileNumber}`
        : ''
    }`;

    receipt += commands.EOL;
    receipt += 'GST No. : ' + this.restaurantDetails?.gstNumber;
    receipt += commands.EOL;
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR_TEXT_INVOICE_58MM;
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_CT;
    receipt += counterType;
    receipt += commands.EOL;
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_LT;
    receipt += 'Date : ' + orderDateTime;
    receipt += commands.EOL;
    receipt += 'Table No : ' + posOrder?.tableName;
    receipt += commands.EOL;
    receipt += 'Bill No : ' + posOrder?.billNo;
    receipt += commands.EOL;
    if (orderDetails?.restaurantCustomerDetails?.name) {
      receipt += commands.EOL;
      receipt += commands.HORIZONTAL_LINE.HR_TEXT_CUSTOMER_58MM;
      receipt += 'Name : ' + orderDetails?.restaurantCustomerDetails?.name.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
      receipt += commands.EOL;
      if(orderDetails?.restaurantCustomerDetails?.address){
        receipt += 'Address : ' + orderDetails?.restaurantCustomerDetails?.address.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
         receipt += commands.EOL;
      }
      receipt += orderDetails?.restaurantCustomerDetails?.mobileNumber
      ? 'Mobile No : '+'+91 ' + orderDetails?.restaurantCustomerDetails?.mobileNumber.substr(0,5)+' ' +orderDetails?.restaurantCustomerDetails?.mobileNumber.substr(5)
      : '';
      receipt += commands.EOL;
    }
    receipt += commands.HORIZONTAL_LINE.HR1_58MM;
    receipt += commands.EOL;
    receipt += 'Item            Qty  Price   Amt';
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR1_58MM;
    receipt += commands.EOL;
    // Dynamic Order List Manage
    orderList.forEach((element) => {
      let itemLength: number = 16 - element.itemName.length;
      element.quantity = element.quantity.toString();
      element.price = element.price.toString();
      element.total = element.total.toString();
      if (element.itemName.length > 16) {
        let itemsName = element.itemName.match(/.{1,15}/g);
        itemsName.forEach((item, index) => {
          let itemNameLength = 16 - item.length;
          if (index == 0) {
            receipt += `${
              item +
              Array(itemNameLength > 0 ? itemNameLength : 0)
                .fill('\x20')
                .join('')
            }${
              element.quantity.length == 1
                ? '  ' + element.quantity
                : element.quantity.length == 2
                ? ' ' + element.quantity
                : element.quantity
            }  ${
              element.price.length == 2
                ? '   ' + element.price
                : element.price.length == 3
                ? '  ' + element.price
                : ' ' + element.price
            } ${
              element.total.length == 2
                ? '   ' + element.total
                : element.total.length == 3
                ? '  ' + element.total
                : ' ' + element.total
            }`;
          } else {
            receipt += commands.EOL;
            receipt += `${
              item.trim() +
              Array(itemNameLength > 0 ? itemNameLength : 0)
                .fill('\x20')
                .join('')
            }`;
          }
        });
      } else {
        receipt += `${
          element.itemName +
          Array(itemLength > 0 ? itemLength : 0)
            .fill('\x20')
            .join('')
        }${
          element.quantity.length == 1
            ? '  ' + element.quantity
            : element.quantity.length == 2
            ? ' ' + element.quantity
            : element.quantity
        }  ${
          element.price.length == 2
            ? '   ' + element.price
            : element.price.length == 3
            ? '  ' + element.price
            : ' ' + element.price
        } ${
          element.total.length == 2
            ? '   ' + element.total
            : element.total.length == 3
            ? '  ' + element.total
            : ' ' + element.total
        }`;
        // receipt += `${itemLength > 0? element.name + Array(itemLength >0?itemLength:0).fill('\x20').join(''):element.name.substring(0, 14) + ' -' +commands.EOL + element.name.substring(14,element.name.length) }${element.qty.length == 1 ? '  ' + element.qty : element.qty.length == 2 ? ' ' + element.qty : element.qty}  ${element.price.length == 2 ? '   ' + element.price : element.price.length == 3 ? '  ' + element.price : ' ' + element.price} ${element.amt.length == 2 ? '   ' + element.amt : element.amt.length == 3 ? '  ' + element.amt : ' ' + element.amt}`;
      }
      receipt += commands.EOL;
    });
    receipt += commands.HORIZONTAL_LINE.HR1_58MM;
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_RT;
    receipt += 'Sub Total : ' + orderDetails.subTotal;
    if (cgst != 0) {
      receipt += commands.EOL;
      receipt += 'CGST (2.5%): ' + cgst.toFixed(1).toString();
      receipt += commands.EOL;
      receipt += 'SGST (2.5%): ' + sgst.toFixed(1).toString();
    }
    if (vat != 0) {
      receipt += commands.EOL;
      receipt += 'VAT : ' + vat.toFixed(1).toString();
    }
    receipt += commands.EOL;
    if (posOrder.billDis != 0) {
      receipt += 'Discount : ' + posOrder.billDis;
      receipt += commands.EOL;
    }
    if (sum != 0) {
      receipt += 'Item Discount : ' + sum.toString();
      receipt += commands.EOL;
    }
    receipt += commands.HORIZONTAL_LINE.HR1_58MM;
    receipt += commands.EOL;
    receipt += 'Grand Total : RS ' + grandTotal.toFixed(0).toString();
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR1_58MM;
    receipt += commands.EOL;
    if (paymentMode.length) {
      receipt += 'Payment Mode';
      receipt += commands.EOL;
      paymentMode.forEach((element: any) => {
        receipt += `${element?.type}-${element?.amount}`;
        receipt += commands.EOL;
      });
      // receipt += commands.EOL;
      receipt += commands.HORIZONTAL_LINE.HR1_58MM;
    }
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_CT;
    receipt += 'Powered by Digipos';
    receipt += commands.EOL;
    receipt += 'www.thedigipos.in';
    receipt += commands.EOL;
    // receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR_THANKS_58MM;
    receipt += commands.EOL;
    // receipt += commands.EOL;
    // receipt += commands.EOL;
    let selectedPrinter = localStorage.getItem(
      LOCAL_STORAGE_KEYS.BLUETOOTH_PRINTER_DETAILS
    );
    if (selectedPrinter) {
      receipt += commands.EOL;
      receipt += commands.EOL;
      receipt += commands.EOL;
    }

    let printerDetail = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.PRINTER_SETTING_DETAILS)
    );
    if (selectedPrinter) {
      this.writeOut(receipt, 0);
    } else if (printerDetail) {
      this.flushReceipt(receipt);
    } else {
      this.sharedService.errorToast('please select a printer first!');
    }
  }

  //3 Inch Printer Service
  async printOrderReceiptFor3inch(
    orderList,
    orderDetails,
    posOrder,
    counterType
  ) {
    await this.getRestaurantDetailsFromIndexDb();
    let orderItems = orderList;
    let totalGst = orderItems.map((x) => x.vat.gst);
    let totalVat = orderItems.map((x) => x.vat.vat);
    let cgstAmount = totalGst.reduce(
      (a, b) => parseFloat(a) + parseFloat(b),
      0
    );
    let vatAmount: any = totalVat.reduce(
      (a, b) => parseFloat(a) + parseFloat(b),
      0
    );
    let cgst = cgstAmount / 2;
    let sgst = cgstAmount / 2;
    let vat = vatAmount;
    let grandTotal =
      parseFloat(posOrder.subTotal) + parseFloat(cgstAmount) + parseFloat(vat);
    grandTotal = grandTotal - parseFloat(posOrder.billDis);
    let sum = 0;
    sum = orderList.reduce((accumulator, object) => {
      return accumulator + object.totalDisAmount;
    }, 0);

    let paymentMode: any[] = this.sharedService.convertStringToJson(
      posOrder.paymentDetails
    );
    let tableName: any;
    let orderDateTime: any;
    if (orderDetails?.PosTable) {
      tableName =
        orderDetails?.PosTable.shortName.toUpperCase() +
        '-' +
        orderDetails?.PosTable?.hotkey;

      Object.assign(posOrder, { tableName: tableName });
      orderDateTime = `${posOrder.orderDate} ${posOrder.orderTime}`;
    } else {
      orderDateTime = moment(new Date()).format('DD/MM/YYYY hh:mm A');
    }
    let receipt = '';
    receipt += commands.HARDWARE.HW_INIT;
    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_CT;
    receipt += this.restaurantDetails?.restaurantName;
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    receipt += this.restaurantDetails?.restaurantAddress;
    receipt += commands.EOL;
    receipt += `${'Mo - '+'+91 ' + this.restaurantDetails?.mobileNumber}${
      this.restaurantDetails?.alternativeMobileNumber
        ? `/${this.restaurantDetails?.alternativeMobileNumber}`
        : ''
    }`;
    receipt += commands.EOL;
    receipt += 'GST No. : ' + this.restaurantDetails?.gstNumber;
    receipt += commands.EOL;
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR_TEXT_INVOICE_80MM;
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_CT;
    receipt += counterType;
    receipt += commands.EOL;
    receipt += 'Date : ' + orderDateTime;
    receipt += commands.EOL;
    receipt += 'Table No : ' + posOrder.tableName;
    receipt += commands.EOL;
    //receipt += commands.TEXT_FORMAT.TXT_ALIGN_RT;
    receipt += 'Bill No : ' + posOrder.billNo;
    receipt += commands.EOL;
    if (orderDetails?.restaurantCustomerDetails?.name) {
      receipt += commands.EOL;
      receipt += commands.HORIZONTAL_LINE.HR_TEXT_CUSTOMER_80MM;
      receipt += 'Name : ' + orderDetails?.restaurantCustomerDetails?.name.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
      receipt += commands.EOL;
      if(orderDetails?.restaurantCustomerDetails?.address){
        receipt += 'Address : ' + orderDetails?.restaurantCustomerDetails?.address.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
        receipt += commands.EOL;
      }
      receipt += orderDetails?.restaurantCustomerDetails?.mobileNumber
      ? 'Mobile No : '+'+91 ' + orderDetails?.restaurantCustomerDetails?.mobileNumber.substr(0,5)+' ' +orderDetails?.restaurantCustomerDetails?.mobileNumber.substr(5)
      : '';
      receipt += commands.EOL;
    }
    receipt += commands.HORIZONTAL_LINE.HR1_80MM;
    receipt += commands.EOL;
    receipt += 'Item                         Qty    Price   Amt';
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR1_80MM;
    receipt += commands.EOL;
    //Dynamic Order List Manage
    orderList.forEach((element) => {
      let itemLength: number = 28 - element.itemName.length;
      element.quantity = element.quantity.toString();
      element.price = element.price.toString();
      element.total = element.total.toString();
      if (element.itemName.length > 28) {
        let itemsName = element.itemName.match(/.{1,27}/g);
        itemsName.forEach((item, index) => {
          let itemNameLength = 28 - item.length;
          if (index == 0) {
            receipt += `${
              item +
              Array(itemNameLength > 0 ? itemNameLength : 0)
                .fill('\x20')
                .join('')
            }${
              element.quantity.length == 1
                ? '   ' + element.quantity
                : element.quantity.length == 2
                ? '  ' + element.quantity
                : element.quantity.length == 3
                ? ' ' + element.quantity
                : element.quantity
            }  ${
              element.price.length == 2
                ? '    ' + element.price
                : element.price.length == 3
                ? '   ' + element.price
                : element.price.length == 4
                ? '  ' + element.price
                : ' ' + element.price
            } ${
              element.total.length == 2
                ? '    ' + element.total
                : element.total.length == 3
                ? '   ' + element.total
                : element.total.length == 4
                ? '  ' + element.total
                : ' ' + element.total
            }`;
          } else {
            receipt += commands.EOL;
            receipt += `${
              item.trim() +
              Array(itemNameLength > 0 ? itemNameLength : 0)
                .fill('\x20')
                .join('')
            }`;
          }
        });
      } else {
        // receipt += `${element.name + Array(itemLength > 0 ? itemLength : 0).fill('\x20').join('')}${element.quantity.length == 1 ? '   ' + element.quantity : element.quantity.length == 2 ? '  ' + element.quantity : element.quantity.length == 3 ? ' ' + element.quantity : element.quantity}  ${element.price.length == 2 ? '    ' + element.price : element.price.length == 3 ? '   ' + element.price : element.price.length == 4 ? '  ' + element.price : ' ' + element.price} ${element.total.length == 2 ? '    ' + element.total : element.total.length == 3 ? '   ' + element.total : element.total.length == 4 ? '  ' + element.total : ' ' + element.total}`;
        receipt += `${
          element.itemName +
          Array(itemLength > 0 ? itemLength : 0)
            .fill('\x20')
            .join('')
        }${
          element.quantity.length == 1
            ? '   ' + element.quantity
            : element.quantity.length == 2
            ? '  ' + element.quantity
            : element.quantity.length == 3
            ? ' ' + element.quantity
            : element.quantity
        }  ${
          element.price.length == 2
            ? '    ' + element.price
            : element.price.length == 3
            ? '   ' + element.price
            : element.price.length == 4
            ? '  ' + element.price
            : ' ' + element.price
        } ${
          element.total.length == 2
            ? '    ' + element.total
            : element.total.length == 3
            ? '   ' + element.total
            : element.total.length == 4
            ? '  ' + element.total
            : ' ' + element.total
        }`;
      }

      receipt += commands.EOL;
    });
    receipt += commands.HORIZONTAL_LINE.HR1_80MM;
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_RT;
    receipt += 'Sub Total : ' + orderDetails.subTotal;
    if (cgst != 0) {
      receipt += commands.EOL;
      receipt += 'CGST (2.5%): ' + cgst.toFixed(1).toString();
      receipt += commands.EOL;
      receipt += 'SGST (2.5%): ' + sgst.toFixed(1).toString();
    }
    if (vat != 0) {
      receipt += commands.EOL;
      receipt += 'VAT : ' + vat.toFixed(1).toString();
    }
    receipt += commands.EOL;
    if (posOrder.billDis != 0) {
      receipt += 'Discount : ' + posOrder.billDis;
      receipt += commands.EOL;
    }
    if (sum != 0) {
      receipt += 'Item Discount : ' + sum.toString();
      receipt += commands.EOL;
    }
    receipt += commands.HORIZONTAL_LINE.HR1_80MM;
    receipt += commands.EOL;
    receipt += 'Grand Total : ' + grandTotal.toFixed(0).toString();
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR1_80MM;
    receipt += commands.EOL;
    if (paymentMode.length) {
      receipt += 'Payment Mode';
      receipt += commands.EOL;
      paymentMode.forEach((element: any) => {
        receipt += `${element?.type}-${element?.amount}`;
        receipt += commands.EOL;
      });
      // receipt += commands.EOL;
      receipt += commands.HORIZONTAL_LINE.HR1_80MM;
    }
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_CT;
    receipt += 'Powered by Digipos';
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_CT;
    receipt += 'www.thedigipos.in';
    receipt += commands.EOL;
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR_THANKS_80MM;
    receipt += commands.EOL;
    let selectedPrinter = localStorage.getItem(
      LOCAL_STORAGE_KEYS.BLUETOOTH_PRINTER_DETAILS
    );
    if (selectedPrinter) {
      receipt += commands.EOL;
      receipt += commands.EOL;
      receipt += commands.EOL;
    }

    let printerDetail = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.PRINTER_SETTING_DETAILS)
    );

    if (selectedPrinter) {
      this.writeOut(receipt, 0);
    } else if (printerDetail) {
      this.flushReceipt(receipt);
    } else {
      this.sharedService.errorToast('please select a printer first!');
    }
  }

  flushReceipt(receipt) {
    this.printService
      .init()
      .setSize('normal')
      .writeLine(receipt)
      .feed(4)
      .cut('full')
      .flush();
  }

  doTestPrint() {
    var esc = '\x1B'; //ESC byte in hex notation
    var newLine = '\x0A'; //LF byte in hex notation

    var cmds = esc + '@'; //Initializes the printer (ESC @)
    cmds += esc + '!' + '\x38'; //Emphasized + Double-height + Double-width mode selected (ESC ! (8 + 16 + 32)) 56 dec => 38 hex
    cmds += 'BEST DEAL STORES'; //text to print
    cmds += newLine + newLine;
    cmds += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)
    cmds += 'COOKIES                   5.00';
    cmds += newLine;
    cmds += 'MILK 65 Fl oz             3.78';
    cmds += newLine + newLine;
    cmds += 'SUBTOTAL                  8.78';
    cmds += newLine;
    cmds += 'TAX 5%                    0.44';
    cmds += newLine;
    cmds += 'TOTAL                     9.22';
    cmds += newLine;
    cmds += 'CASH TEND                10.00';
    cmds += newLine;
    cmds += 'CASH DUE                  0.78';
    cmds += newLine + newLine;
    cmds += esc + '!' + '\x18'; //Emphasized + Double-height mode selected (ESC ! (16 + 8)) 24 dec => 18 hex
    cmds += '# ITEMS SOLD 2';
    cmds += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)
    cmds += newLine + newLine;
    cmds += '11/03/13  19:53:17';

    let selectedPrinter = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.BLUETOOTH_PRINTER_DETAILS)
    );
    let printerDetail = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.PRINTER_SETTING_DETAILS)
    );

    if (selectedPrinter) {
      if (selectedPrinter.connected) {
        let encoder = new TextEncoder();
        let text = encoder.encode(cmds);
        return this.printCharacteristic.writeValue(text).then(() => {
        });
      } else {
        this.sharedService.errorToast('please select a printer first!');
      }
    } else if (printerDetail) {
      this.flushReceipt(cmds);
    } else {
      this.sharedService.errorToast('please select a printer first!');
    }
  }

  async generateKotNumber() {
    let objKotNumber: any = {
      currentDate: '',
      kotNumber: '',
    };

    let kotNumberDetails: any = await this.indexDbService.getDataFromIndexedDB(
      LOCAL_STORAGE_KEYS.KOT_NUMBER
    );

    if (kotNumberDetails) {
      objKotNumber = { ...kotNumberDetails };
    }

    if (objKotNumber.currentDate == moment(new Date()).format('DD/MM/YYYY')) {
      objKotNumber.kotNumber = parseInt(kotNumberDetails.kotNumber) + 1;
      objKotNumber.kotNumber =
        parseInt(objKotNumber.kotNumber) < 10
          ? `0${objKotNumber.kotNumber}`
          : objKotNumber.kotNumber;
    } else {
      objKotNumber.currentDate = moment(new Date()).format('DD/MM/YYYY');
      objKotNumber.kotNumber = '01';
    }

    await this.indexDbService.setDataInIndexedDBWithAwait(
      LOCAL_STORAGE_KEYS.KOT_NUMBER,
      objKotNumber
    );
    return objKotNumber.kotNumber;
  }
}
