import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { LOCAL_STORAGE_KEYS } from '../global/constant';
import { IndexDbStorageService } from './localstorage.service';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  isSpinnerShow = new BehaviorSubject<boolean>(false);

  isTableDataShow = new BehaviorSubject<boolean>(false);

  isSideBarShow = new BehaviorSubject<boolean>(false);

  tableDataShow = this.isTableDataShow.asObservable();

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private indexDbService: IndexDbStorageService
  ) {}

  gotoPage(url: string) {
    this.router.navigateByUrl(url);
  }

  showSpinner() {
    this.isSpinnerShow.next(true);
  }

  hideSpinner() {
    this.isSpinnerShow.next(false);
  }

  //#region Toastr Functions

  successToast(message: any, title?: any) {
    return this.toastr.success(message, title);
  }

  errorToast(message: any, title?: any) {
    return this.toastr.error(message, title);
  }

  warningToast(message: any, title?: any) {
    return this.toastr.warning(message, title);
  }

  infoToast(message: any, title?: any) {
    return this.toastr.info(message, title);
  }
  //#endregion

  // Get Form Controls Control
  getFormControls(formGroupName: FormGroup) {
    return formGroupName.controls;
  }

  // Show Table data Behaivior Function
  onTableDataShow(data) {
    return this.isTableDataShow.next(data ? data : false);
  }

  get restaurantId() {
    return localStorage.getItem('restaurantId');
  }

  async restaurantUserDetails() {
    const userDetails = await this.indexDbService.getRestaurantAuthData(
      LOCAL_STORAGE_KEYS.USER_DETAILS
    );
    return userDetails;
  }

  convertStringToJson(itemsList) {
    return JSON.parse(itemsList);
  }

  numberWithCommas(x) {
    if (!x) return 0;
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  dateFormatType(date) {
    return moment(date).format('DD/MM/YYYY');
  }

  dateAndTimeFormatType(date) {
    return moment(date).format('DD/MM/YYYY hh:mm A');
  }

  toISOString(date: any) {
    return date.toISOString();
  }

  getIsoFromAndToDate(date: Date, dateType) {
    date = new Date(date);
    if (dateType == 'fromDate') {
      date.setUTCHours(0, 0, 0, 0);
    } else {
      date.setUTCHours(23, 59, 0, 0);
    }
    return date.toISOString();
  }

  convertDateTimetoIsoString(date) {
    var tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-',
      pad = function (num) {
        return (num < 10 ? '0' : '') + num;
      };

    return (
      date.getFullYear() +
      '-' +
      pad(date.getMonth() + 1) +
      '-' +
      pad(date.getDate()) +
      'T' +
      pad(date.getHours()) +
      ':' +
      pad(date.getMinutes()) +
      ':' +
      pad(date.getSeconds()) +'.'+
      (date.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5) +
      'Z'
    );
  }
}
