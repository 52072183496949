import { Injectable } from '@angular/core';
import { APIURL } from '../global/constant';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class SalesReportService {
  constructor(private commonService: CommonService) {}

  getPosTableList(payload) {
    let param = this.commonService.getParamsFromObj(payload);
    return this.commonService.getWithTokenWithParams(APIURL.sales, param);
  }

  deleteSales(id: any) {
    return this.commonService.deleteWithToken(`${APIURL.sales}/${id}`);
  }

  downloadSalesReportExcel(id: any,payload) {
    let param = this.commonService.getParamsFromObj(payload);
    return this.commonService.postBlob(`${APIURL.exportSalesReports}/${id}`,param);
  }

  
  
}
