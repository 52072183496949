import { Injectable } from '@angular/core';
import { APIURL } from '../global/constant';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class SubCategoryService {
  constructor(private commonService: CommonService) {}

  getSubCategoryList(payload) {
    let param = this.commonService.getParamsFromObj(payload);
    return this.commonService.getWithTokenWithParams(
      APIURL.subCategory,
      param
    );
  }
}
