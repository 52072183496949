import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { APIURL } from '../global/constant';

@Injectable({
  providedIn: 'root'
})
export class ExpenseReportService {

  constructor(private commonService: CommonService) { }

  addExpenseHead(expenseData) {
    return this.commonService.postWithToken(APIURL.AddExpenseHead, expenseData);
  }

  getExpenseHead(payload) {
    let param = this.commonService.getParamsFromObj(payload);
    return this.commonService.getWithTokenWithParams(APIURL.getExpenseHead, param);
  }

  getExpenseReport(payload) {
    let param = this.commonService.getParamsFromObj(payload);
    return this.commonService.getWithTokenWithParams(APIURL.getExpenseReport, param);
  }

  addExpenseReport(expenseData) {
    return this.commonService.postWithToken(APIURL.AddExpenseReport, expenseData);
  }

  updateExpenseReport(expenseData, id) {
    return this.commonService.postWithToken(`${APIURL.AddExpenseReport}?expenseReportId=${id}`, expenseData);
  }
}
