export const APIURL = {
  // Operator Login
  syncRestaurantDetails: '/operator-auth/syncRestaurantDetails',
  operatorLogin: '/operator-auth/login',

  // Counter Type & Payment Type & Section Type
  counterType: '/counter-type',
  paymentType: '/payment-type',
  tableAggragator: '/table-aggragator',
  sectionType: '/section-type',

  posTable: '/pos-tables',
  category: '/category',
  subCategory: '/sub-category',
  product: '/product',
  favoriteProduct: '/product/favorite',
  sales: '/pos-orders',
  cashbox: '/cashbox',
  restaurantPaymentMode: '/restaurant-payment-mode',
  supplier: '/supplier',
  supplierStatement: '/supplier/account-statment',

  addPurchaseEntry: '/purchase/purchase-entry',
  getPurchaseReports: '/purchase/reports',
  getPurchaseEntryProduct: '/purchase/purchase-entry-products',
  dashboardCounters: '/dashboard/counters',
  kotReports: '/kot-reports',
  inventory: '/inventory',
  updateInventory: '/inventory/update-inventory-stock',

  exportSalesReports: '/pos-orders/exportSalesRepors',

  posOrderCheckOut: '/pos-orders/syncSaveCheckoutOrder',

  customerReport: '/customer',

  AddCustomer: '/customer',

  paymentReport: '/payment-report',

  AddExpenseHead: '/expense-report/create-expense-head',
  getExpenseHead: '/expense-report/expense-head-list',

  AddExpenseReport: '/expense-report/add-expense',
  getExpenseReport: '/expense-report/expense-report-list',

  //create sub tables 

  createSubTable:'/pos-tables/create-po-sub-table',
  deleteSubTable:'/pos-tables/delete-sub-table'
};

export const LOCAL_STORAGE_KEYS = {
  TOKEN: 'token',
  RESTAURANT_DETAILS: 'restaurant_details',
  USER_DETAILS: 'user_details',
  COUNTER_TYPE: 'COUNTER_TYPE',
  PAYMENT_TYPE: 'PAYMENT_TYPE',
  RESTAURANT_PAYMENT_TYPE: 'RESTAURANT_PAYMENT_TYPE',
  ONLINE_TABLE_AGGRAGATOR: 'ONLINE_TABLE_AGGRAGATOR',
  SECTION_TYPE: 'SECTION_TYPE',
  POS_TABLES_LIST: 'POS_TABLES_LIST',
  CATEGORY_LIST: 'CATEGORY_LIST',
  SUB_CATEGORY_LIST: 'SUB_CATEGORY_LIST',
  PRODUCT_LIST: 'PRODUCT_LIST',
  SELECTED_TABLE: 'SELECTED_TABLE',
  RUNNUNG_ORDER_DATA: 'RUNNING_ORDER_DATA',
  KOT_NUMBER: 'KOT_NUMBER',
  CHECKOUT_ORDER_DATA: 'CHECKOUT_ORDER_DATA',
  SELECTED_SECTION: 'SELECTED_SECTION',
  ALL_TABLE_SECTION: 'ALL_TABLE',
  PRINTER_SIZE: 'PRINTER_SIZE',
  PAYMENT_DETAILS_CASHBOX: 'PAYMENT_DETAILS_CASHBOX',
  PRINTER_SETTING_DETAILS: 'PRINTER_SETTING_DETAILS',
  SYNCRUNNINGTABLE: 'SYNCRUNNINGTABLE',
  SYNCCHECKOUTORDERORDER: 'SYNCCHECKOUTORDERORDER',
  SAVECHECKOUTORDER: 'SAVECHECKOUTORDER',
  CUSTOMER_REPORT_LIST: 'CUSTOMER_REPORT_LIST',
  NEW_CUSTOMER_ID:'NEW_CUSTOMER_ID',
  PAYMENT_REPORT:'PAYMENT_REPORT',
  BLUETOOTH_PRINTER_DETAILS:'BLUETOOTH_PRINTER_DETAILS',
  BILL_DETAILS: 'BILL_DETAILS',

};

export const SHORTCUT_KEYS ={
  KEYS_DOWN:'window:keydown.',
  KOT_PRINT:'Alt.k',
  CHECKOUT_PRINT:'Shift.p',
  PROFROMA_PRINT:'Alt.P',
  DELETE_ORDER:'Shift.Delete',
  GO_BILLING:'Alt.B',
  ADD_TABLE:'Alt.T',
  OPEN_CHECKOUT_MODAL:'Alt.c',
  VIEW_BILL:'Alt.v',
  KOT_REPORT:'Shift.Alt.k',
  SALES_REPORT:'Shift.Alt.s',
  PAYMENT_REPORT:'Shift.Alt.p',
  EXPENSE_REPORT:'Shift.Alt.e',
  CUSTOMER_REPORT:'Shift.Alt.c',
  GO_DASHBOARD:'Alt.d',
  GO_TO_BLUETOOTHPRINTER:'Shift.Alt.b',
  GO_TO_USbPRINTER:'Shift.Alt.u',
}
// ThermalPrinter Commands
export const commands = {
  LF: '\x0a',
  ESC: '\x1b',
  FS: '\x1c',
  GS: '\x1d',
  US: '\x1f',
  FF: '\x0c',
  DLE: '\x10',
  DC1: '\x11',
  DC4: '\x14',
  EOT: '\x04',
  NUL: '\x00',
  EOL: '\n',
  HORIZONTAL_LINE: {
    // 2 Inch Printer Formate Lines
    HR_58MM: '================================',
    HR1_58MM: '--------------------------------',
    HR_TEXT_INVOICE_58MM: '--------- TAX INVOICE ---------',
    HR_TEXT_CUSTOMER_58MM:'------- CUSTOMER DETAIL --------',
    HR_THANKS_58MM: '**** Thank You, Visit Again ****',
    HR2_58MM: '********************************',

    // 3 Inch Printer Formate Lines
    HR_80MM: '================================================',
    HR1_80MM: '------------------------------------------------',
    HR_TEXT_INVOICE_80MM: '----------------- TAX INVOICE  -----------------',
    HR_TEXT_CUSTOMER_80MM: '--------------- CUSTOMER DETAILS  --------------',
    HR_THANKS_80MM: '************ Thank You, Visit Again ************',
    HR2_80MM: '************************************************',
  },
  FEED_CONTROL_SEQUENCES: {
    CTL_LF: '\x0a', // Print and line feed
    CTL_FF: '\x0c', // Form feed
    CTL_CR: '\x0d', // Carriage return
    CTL_HT: '\x09', // Horizontal tab
    CTL_VT: '\x0b', // Vertical tab
  },
  LINE_SPACING: {
    LS_DEFAULT: '\x1b\x32',
    LS_SET: '\x1b\x33',
  },
  HARDWARE: {
    HW_INIT: '\x1b\x40', // Clear data in buffer and reset modes
    HW_SELECT: '\x1b\x3d\x01', // Printer select
    HW_RESET: '\x1b\x3f\x0a\x00', // Reset printer hardware
  },
  CASH_DRAWER: {
    CD_KICK_2: '\x1b\x70\x00', // Sends a pulse to pin 2 []
    CD_KICK_5: '\x1b\x70\x01', // Sends a pulse to pin 5 []
  },
  MARGINS: {
    BOTTOM: '\x1b\x4f', // Fix bottom size
    LEFT: '\x1b\x6c', // Fix left size
    RIGHT: '\x1b\x51', // Fix right size
  },
  PAPER: {
    PAPER_FULL_CUT: '\x1d\x56\x00', // Full cut paper
    PAPER_PART_CUT: '\x1d\x56\x01', // Partial cut paper
    PAPER_CUT_A: '\x1d\x56\x41', // Partial cut paper
    PAPER_CUT_B: '\x1d\x56\x42', // Partial cut paper
  },
  TEXT_FORMAT: {
    TXT_NORMAL: '\x1b\x21\x00', // Normal text
    TXT_2HEIGHT: '\x1b\x21\x10', // Double height text
    TXT_2WIDTH: '\x1b\x21\x20', // Double width text
    TXT_4SQUARE: '\x1b\x21\x30', // Double width & height text
    TXT_CUSTOM_SIZE: function (width, height) {
      // other sizes
      var widthDec = (width - 1) * 16;
      var heightDec = height - 1;
      var sizeDec = widthDec + heightDec;
      return '\x1d\x21' + String.fromCharCode(sizeDec);
    },

    TXT_HEIGHT: {
      1: '\x00',
      2: '\x01',
      3: '\x02',
      4: '\x03',
      5: '\x04',
      6: '\x05',
      7: '\x06',
      8: '\x07',
    },
    TXT_WIDTH: {
      1: '\x00',
      2: '\x10',
      3: '\x20',
      4: '\x30',
      5: '\x40',
      6: '\x50',
      7: '\x60',
      8: '\x70',
    },

    TXT_UNDERL_OFF: '\x1b\x2d\x00', // Underline font OFF
    TXT_UNDERL_ON: '\x1b\x2d\x01', // Underline font 1-dot ON
    TXT_UNDERL2_ON: '\x1b\x2d\x02', // Underline font 2-dot ON
    TXT_BOLD_OFF: '\x1b\x45\x00', // Bold font OFF
    TXT_BOLD_ON: '\x1b\x45\x01', // Bold font ON
    TXT_ITALIC_OFF: '\x1b\x35', // Italic font ON
    TXT_ITALIC_ON: '\x1b\x34', // Italic font ON
    TXT_FONT_A: '\x1b\x4d\x00', // Font type A
    TXT_FONT_B: '\x1b\x4d\x01', // Font type B
    TXT_FONT_C: '\x1b\x4d\x02', // Font type C
    TXT_ALIGN_LT: '\x1b\x61\x00', // Left justification
    TXT_ALIGN_CT: '\x1b\x61\x01', // Centering
    TXT_ALIGN_RT: '\x1b\x61\x02', // Right justification
  },
};
