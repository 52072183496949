import { Injectable } from '@angular/core';
import { APIURL } from '../global/constant';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class PosTableService {
  constructor(private commonService: CommonService) {}

  getPosTableList(payload) {
    let param = this.commonService.getParamsFromObj(payload);
    return this.commonService.getWithTokenWithParams(APIURL.posTable, param);
  }

  addPosTable(data) {
    return this.commonService.postWithToken(APIURL.posTable, data);
  }

  createPosTable(data) {
    return this.commonService.postWithToken(APIURL.createSubTable, data);
  }

  deleteSubTable(tableId){
    return this.commonService.deleteWithToken(`${APIURL.deleteSubTable}/${tableId}`);
  }
}
