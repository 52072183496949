import { Injectable } from '@angular/core';
import { APIURL } from '../global/constant';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class SupplierReportService {

  constructor(private commonService: CommonService) { }

  getSupplierList(payload) {
    let param = this.commonService.getParamsFromObj(payload);
    return this.commonService.getWithTokenWithParams(APIURL.supplier, param);
  }

  addSuplierData(supplierData) {
    return this.commonService.postWithToken(APIURL.supplier, supplierData);
  }

  addSupplierAccountStatment(supplierAccountStatement){
    return this.commonService.postWithToken(APIURL.supplierStatement, supplierAccountStatement);
  }

  getSupplierAccountStatementList(payload){
    let param = this.commonService.getParamsFromObj(payload);
    return this.commonService.getWithTokenWithParams(APIURL.supplierStatement, param);
  }
}
