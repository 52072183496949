import { Injectable } from '@angular/core';
import { APIURL } from '../global/constant';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private commonService: CommonService) {}

  getProductList(payload) {
    let param = this.commonService.getParamsFromObj(payload);
    return this.commonService.getWithTokenWithParams(APIURL.product, param);
  }

  addRemoveFavoriteProduct(data, productId) {
    return this.commonService.postWithToken(
      `${APIURL.favoriteProduct}/${productId}`,
      data
    );
  }
}
