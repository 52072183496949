import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService, PrinterSetupService, SharedService } from './services';
import { fromEvent, merge, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LOCAL_STORAGE_KEYS } from './global/constant';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  networkStatus: any;
  networkStatus$: Subscription = Subscription.EMPTY;
  printerData: any;
  _navigator: any;
  constructor(
    public sharedService: SharedService,
    private spinner: NgxSpinnerService,
    private commanService: CommonService,
    private modal: NzModalService,
    public printerSetupService: PrinterSetupService,
    public router: Router
  ) {
    this._navigator = navigator;
    this.sharedService.isSpinnerShow.subscribe((isShow) => {
      if (isShow) {
        this.spinner.show();
      } else {
        this.spinner.hide();
      }
    });
  }
  ngOnInit() {
    // this.commanService.disableRightClick();
    console.log = () => {};
    console.warn = () => {};
    console.error = () => {};
    this.checkNetworkStatus();
    this.getprinterDetailsInLocalStorage();
    this.disconnected();
  }

  getprinterDetailsInLocalStorage() {
    this.printerData = JSON.parse(localStorage.getItem(
      LOCAL_STORAGE_KEYS.BLUETOOTH_PRINTER_DETAILS
    ));
  }

  disconnected() {
    if (this.printerData) {
      this.printerData.connected = false;
      this.printerData = localStorage.setItem(
        LOCAL_STORAGE_KEYS.BLUETOOTH_PRINTER_DETAILS,
        JSON.stringify(this.printerData)
      );
      this.sharedService.errorToast(
        'bluetooth device disconnected on page refresh'
      );
      // this.router.navigate(['/pos/configuration/bluetooth-printer-setup']);
    } else {
      return;
    }
  }

  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe((status) => {
        this.networkStatus = status;
        if (!this.networkStatus) {
          this.warning();
        }
      });
  }
  warning() {
    this.modal.warning({
      nzWidth: '30%',
      nzStyle: { fontSize: '12px' },
      nzTitle: 'Opps! Internet Disconnected',
      nzCentered: true,
      nzOkText: 'Okay',
      nzContent:
        'You can still use Digipos in offline mode and your data will auto matically save on server whenever internet connection is back.',
    });
  }
}
