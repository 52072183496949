import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { APIURL } from '../global/constant';

@Injectable({
  providedIn: 'root',
})
export class PaymentTypeService {
  constructor(private commonService: CommonService) {}

  getPaymentType() {
    return this.commonService.getWithToken(APIURL.paymentType);
  }

  getCashBoxDetailsFromAPI(payload){
    let param = this.commonService.getParamsFromObj(payload);
    return this.commonService.getWithTokenWithParams(APIURL.cashbox, param);
  }

  setCashBoxDetailsUsingAPI(data){
    return this.commonService.postWithToken(APIURL.cashbox, data);
  }

  updateCashBoxData(data, params){
    let param = this.commonService.getParamsFromObj(params);
    return this.commonService.putWithTokenAndParams(APIURL.cashbox, data, param);
  }
}
