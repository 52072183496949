import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as io from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { OfflineOrderDataService } from './offline.orderdata.service';
import { SharedService } from './shared.service';
import { fromEvent, merge, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
@Injectable({
  providedIn: 'root',
})
export class SocketIoService {
  socket: any;
  // syncRunningTableList: BehaviorSubject<any[]> = new BehaviorSubject([]);
  // syncRunningTableList: any = [];
  syncCheckoutOrdersList: BehaviorSubject<any> = new BehaviorSubject(false);
  syncCheckoutOrdersDataSpinner: BehaviorSubject<any> = new BehaviorSubject(
    false
  );
  forAcceptedOrderCheck: BehaviorSubject<any> = new BehaviorSubject(false);
  restaurantId: string = localStorage.getItem('restaurantId');
  networkStatus: any;
  networkStatus$: Subscription = Subscription.EMPTY;
  oldCheckOutOrders: any[] = [];
  runningAcceptEkot: boolean = false;
  runningCompleteEkot: boolean = false;

  constructor(
    public offlineOrderService: OfflineOrderDataService,
    public sharedService: SharedService
  ) {
    this.getCheckOutOrders();
    this.setupSocketConnection();
    this.checkNetworkStatus();
  }

  async getCheckOutOrders() {
    this.oldCheckOutOrders =
      await this.offlineOrderService.getCheckoutDataOrderList();
  }

  setupSocketConnection() {
    this.restaurantId = localStorage.getItem('restaurantId');
    this.socket = io(environment.SOCKET_ENDPOINT, {
      withCredentials : true
    });

    // Checkout Order Data.  
    this.socket.on('checkout_' + this.restaurantId, (data: any) => {
      console.log(data.data, "Checkout")
      this.offlineOrderService.savedUnsavedOrders(data.data);
      this.syncCheckoutOrdersList.next(true);
    });

    // Running Order Data.
    this.socket.on(this.restaurantId, async (data: any) => {
      if (data.restaurantId == this.restaurantId) {
        // this.syncRunningTableList = data.data;
       await this.offlineOrderService.saveAsyncRunningOrderInIndexDb(data.data);
        console.log('--------- Running Tables -----------', data);
        this.syncCheckoutOrdersList.next(true);
      }
    });

    //testing
    this.socket.on('hello', (data) => {
      console.log('Hello', data);
    });

    // successCheckOutData Order Data.
    this.socket.on('successCheckOutData_' + this.restaurantId, async (data: any) => {
        const oldSavedOrders = await this.offlineOrderService.getCheckoutDataOrderList();
        if (data && data.length) {
          if (oldSavedOrders) {
            let dataTest = [];
            dataTest = oldSavedOrders.filter((cv) => {
              return !data.find((e) => {
                return e.posOrderId == cv.id;
              });
            });
            this.offlineOrderService.savedUnsavedOrders(dataTest);
          } else {
            this.offlineOrderService.savedUnsavedOrders(data.data);
          }
          this.sharedService.successToast('Order sync successfully');
          this.syncCheckoutOrdersDataSpinner.next(false);
        } else if (data.statusCode == 502) {
          if (data?.data[0].value) {
            const savedRecordDB = _.remove(oldSavedOrders, (x) => {
              return x.id === data?.data[0].value;
            });
            await this.offlineOrderService.savedUnsavedOrders(oldSavedOrders);
            if (oldSavedOrders.length) {
              this.saveCheckOutData(oldSavedOrders);
            }
            if (oldSavedOrders.length == 0) {
              this.sharedService.successToast('Order sync successfully');
            }
          }
        }
      }
    );

    // Sync E-Kot Order Data.
    this.socket.on(
      'syncEKOTOrderDataFromApp_' + this.restaurantId,
      async (data: any) => {
        if (data) {
          const oldSavedOrders =
            await this.offlineOrderService.getDataFromIndexedDB();
          if (oldSavedOrders) {
            let filterData = [];
            oldSavedOrders.filter((res) => {
              if (res?.isEkot) {
                filterData.push(res);
              }
            });
            this.syncRuuningKOTInKitchan(filterData);
          }
        }
      }
    );

    this.socket.on(
      'getAllRunningOrdersFromPos_' + this.restaurantId,
      async (data: any) => {
        const oldSavedOrders =
          await this.offlineOrderService.getDataFromIndexedDB();
        if (oldSavedOrders) {
          this.syncRunningOrdersDataToOwner(oldSavedOrders);
        }
      }
    );

    // Accepted Order come For Kitches App socket
    this.socket.on(
      'syncAcceptOrder_' + this.restaurantId,
      async (data: any) => {
        this.runningAcceptEkot = true;
        const oldSavedOrders =
          await this.offlineOrderService.getDataFromIndexedDB();
        if (this.runningAcceptEkot == true) {
          this.runningAcceptEkot = false;
          if (data) {
            oldSavedOrders.filter((element, index) => {
              if (data.data[0].id == element.id) {
                oldSavedOrders[index] = data.data[0];
                this.offlineOrderService.saveAsyncRunningOrderInIndexDb(
                  oldSavedOrders
                );
              }
            });
            this.forAcceptedOrderCheck.next(true);
          }
        }
      }
    );

    // Completd Order SOcket come to Kitchen App
    this.runningCompleteEkot = false;
    this.socket.on(
      'syncCompletedOrder_' + this.restaurantId,
      async (data: any) => {
        this.runningCompleteEkot = true;
        const oldSavedOrders =
          await this.offlineOrderService.getDataFromIndexedDB();
        if (data) {
          oldSavedOrders.filter((element, index) => {
            if (data.data.id == element.id) {
              oldSavedOrders[index] = data.data;
              this.offlineOrderService.saveAsyncRunningOrderInIndexDb(
                oldSavedOrders
              );
            }
          });
          this.syncCheckoutOrdersList.next(true);
        }
      }
    );

    // When User Login Sync Running Order
    this.socket.on(
      'syncRunningOrderLoginUser_' + this.restaurantId,
      async (data: any) => {
        const oldSavedOrders =
          await this.offlineOrderService.getDataFromIndexedDB();
        if (data) {
          this.syncOrderToNewUser(oldSavedOrders);
        }
      }
    );

    this.socket.on(
      'syncOrderToNewUser_' + this.restaurantId,
      async (data: any) => {
        const oldSavedOrders =
          await this.offlineOrderService.getDataFromIndexedDB();
        if (oldSavedOrders.length) {
          data.data.forEach((element) => {
            if (oldSavedOrders.filter((res) => element.id == res.id)) {
              oldSavedOrders.push(element);
            }
          });
        } else {
          this.offlineOrderService.saveAsyncRunningOrderInIndexDb(data.data);
          this.syncCheckoutOrdersList.next(true);
        }
      }
    );
  }

  listen(eventName: string) {
    return new Observable((subcriber)=>{
      this.socket.on(eventName, (data: any)=>{
        subcriber.next(data);
      })
    })
  }

  async saveCheckOutData(oldData) {
    if (oldData.length != 0) {
      oldData.forEach((order, index) => {
        Object.assign(oldData[index], {
          posOrderId: order.id,
        });
        delete oldData[index].id;
      });
      this.syncCheckOutOrderData(oldData);
    }
  }

  syncOrderToNewUser(data) {
    this.restaurantId = localStorage.getItem('restaurantId');
    this.socket.emit('syncOrderToNewUser', {
      data: data,
      restaurantId: this.restaurantId,
    });
  }

  async disconnect() {
    this.socket.emit('disconnect', {
      data: 'disconnect',
    });
  }

  async testing() {
    const runningTable = await this.offlineOrderService.getDataFromIndexedDB();
    this.socket.emit('testing', {
      data: runningTable,
    });
  }

  async checkoutOrder(data: any) {
    this.socket.emit('saveCheckoutOrders', {
      data: data,
      restaurantId: this.restaurantId,
    });
  }

  async syncCheckOutOrderData(data: any) {
    this.syncCheckoutOrdersDataSpinner.next(true);
    this.socket.emit('syncCheckOutOrderData', {
      data: data,
      restaurantId: this.restaurantId,
    });
  }

  async syncRunningTable(data) {
    this.restaurantId = localStorage.getItem('restaurantId');
    this.socket.emit('syncRunningTable', {
      data: data,
      restaurantId: this.restaurantId,
    });
  }

  syncRuuningKOTInKitchan(data) {
    this.socket.emit('syncRunningKotOrder', {
      data: data,
      restaurantId: this.restaurantId,
    });
  }

  syncRunningOrderLoginUser(restaurantId) {
    this.socket.emit('syncRunningOrderLoginUser', {
      data: true,
      restaurantId: restaurantId,
    });
  }

  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe((status) => {
        this.networkStatus = status;
        if (!status) {
          this.disconnect();
        } else {
          this.setupSocketConnection();
        }
      });
  }

  syncRunningOrdersDataToOwner(data) {
    this.restaurantId = localStorage.getItem('restaurantId');
    this.socket.emit('syncRunningOrdersDataToOwner', {
      data: data,
      restaurantId: this.restaurantId,
    });
  }
}
