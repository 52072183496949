import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { commands, LOCAL_STORAGE_KEYS } from '../global/constant';
import { IRestaurant } from '../shared/models/restaurant.model';
import { CommonService } from './common.service';
import { IndexDbStorageService } from './localstorage.service';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root',
})
export class JSPrintManagerService {
  printersList: string[];
  selectedPrinter: any;
  isDefaultPrinterSelected = false;
  selectedPrinterDetails: any = {};

  restaurantDetails: IRestaurant;
  printerConnectStatus: boolean = false;

  constructor(
    private commonService: CommonService,
    public sharedService: SharedService,
    private indexDbService: IndexDbStorageService
  ) {
    this.getRestaurantDetailsFromIndexDb();
    this.getPrinterDetailsFromLocalStorage();
    this.getInstalledPrinterList();
  }

  getPrinterDetailsFromLocalStorage() {
    this.selectedPrinterDetails = JSON.parse(
      this.indexDbService.getDataFromLocalStorage(
        LOCAL_STORAGE_KEYS.PRINTER_SETTING_DETAILS
      )
    );

    if (this.selectedPrinterDetails) {
      this.selectedPrinter = this.selectedPrinterDetails.printerName;
      this.printerConnectStatus = true;
    } else {
      this.printerConnectStatus = false;
    }
  }

  getRestaurantDetailsFromIndexDb() {
    this.indexDbService
      .getRestaurantAuthData(LOCAL_STORAGE_KEYS.RESTAURANT_DETAILS)
      .then(async (res: any) => {
        this.restaurantDetails = await res;
      })
      .catch((err) => {
        if (err) {
          this.sharedService.errorToast(err);
        }
      });
  }

  getInstalledPrinterList() {
    JSPM.JSPrintManager.auto_reconnect = true;
    JSPM.JSPrintManager.start();
    JSPM.JSPrintManager.WS.onStatusChanged = () => {
      if (this.jspmWSStatus()) {
        // get client installed printers
        JSPM.JSPrintManager.getPrinters().then((myPrinters: string[]) => {
          this.printersList = myPrinters;
        });
      }
    };
  }

  jspmWSStatus(): any {
    if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
      return true;
    } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Closed) {
      // alert('JSPrintManager (JSPM) is not installed or not running! Download JSPM Client App from https://neodynamic.com/downloads/jspm');
      return false;
    } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Blocked) {
      alert('JSPM has blocked this website!');
      return false;
    }
  }

  savePrinterName(printerName) {
    if (printerName) {
      localStorage.setItem('printerName', printerName);
    }
  }

  // 2 Inch Order Receipt Service
  async printOrderReceiptFor2inch(orderList, orderDetails) {
    let orderItems = orderList;
    let totalGst = orderItems.map(x => x.vat.gst);
    let totalVat = orderItems.map(x => x.vat.vat);
    let cgstAmount = totalGst.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    let vatAmount: any = totalVat.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    let cgst = cgstAmount / 2;
    let sgst = cgstAmount / 2;
    let vat = vatAmount;
    let grandTotal = parseFloat(orderDetails.subTotal) + parseFloat(cgstAmount) + parseFloat(vat);
    grandTotal = grandTotal - parseFloat(orderDetails.billDis);
    let receipt = '';
    receipt += commands.HARDWARE.HW_INIT;
    receipt += commands.TEXT_FORMAT.TXT_2WIDTH;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_CT;
    receipt += this.restaurantDetails.restaurantName;
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    receipt += this.restaurantDetails.restaurantAddress;
    receipt += commands.EOL;
    receipt += 'Mo - ' + this.restaurantDetails.mobileNumber;
    receipt += commands.EOL;
    receipt += 'GST No. : ' + this.restaurantDetails.gstNumber;
    receipt += commands.EOL;
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR_TEXT_INVOICE_58MM;
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_CT;
    receipt += 'DINE-IN';
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_LT;
    receipt += 'Date : ' + moment(new Date()).format('DD/MM/YYYY hh:mm A');
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_LT;
    receipt += 'Table No : ' + + orderDetails?.tableName;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_RT;
    receipt += '    Bill No : ' + orderDetails.billNo;
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR1_58MM;
    receipt += commands.EOL;
    receipt += 'Item            Qty  Price   Amt';
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR1_58MM;
    receipt += commands.EOL;
    // Dynamic Order List Manage
    orderList.forEach((element) => {
      let itemLength: number = 16 - element.itemName.length;
      element.quantity = element.quantity.toString();
      element.price = element.price.toString();
      element.total = element.total.toString();
      if (element.itemName.length > 16) {
        let itemsName = element.itemName.match(/.{1,15}/g);
        itemsName.forEach((item, index) => {
          let itemNameLength = 16 - item.length;
          if (index == 0) {
            receipt += `${item +
              Array(itemNameLength > 0 ? itemNameLength : 0)
                .fill('\x20')
                .join('')
              }${element.quantity.length == 1
                ? '  ' + element.quantity
                : element.quantity.length == 2
                  ? ' ' + element.quantity
                  : element.quantity
              }  ${element.price.length == 2
                ? '   ' + element.price
                : element.price.length == 3
                  ? '  ' + element.price
                  : ' ' + element.price
              } ${element.total.length == 2
                ? '   ' + element.total
                : element.total.length == 3
                  ? '  ' + element.total
                  : ' ' + element.total
              }`;
          } else {
            receipt += commands.EOL;
            receipt += `${item.trim() +
              Array(itemNameLength > 0 ? itemNameLength : 0)
                .fill('\x20')
                .join('')
              }`;
          }
        });
      } else {
        receipt += `${element.itemName +
          Array(itemLength > 0 ? itemLength : 0)
            .fill('\x20')
            .join('')
          }${element.quantity.length == 1
            ? '  ' + element.quantity
            : element.quantity.length == 2
              ? ' ' + element.quantity
              : element.quantity
          }  ${element.price.length == 2
            ? '   ' + element.price
            : element.price.length == 3
              ? '  ' + element.price
              : ' ' + element.price
          } ${element.total.length == 2
            ? '   ' + element.total
            : element.total.length == 3
              ? '  ' + element.total
              : ' ' + element.total
          }`;
        // receipt += `${itemLength > 0? element.name + Array(itemLength >0?itemLength:0).fill('\x20').join(''):element.name.substring(0, 14) + ' -' +commands.EOL + element.name.substring(14,element.name.length) }${element.qty.length == 1 ? '  ' + element.qty : element.qty.length == 2 ? ' ' + element.qty : element.qty}  ${element.price.length == 2 ? '   ' + element.price : element.price.length == 3 ? '  ' + element.price : ' ' + element.price} ${element.amt.length == 2 ? '   ' + element.amt : element.amt.length == 3 ? '  ' + element.amt : ' ' + element.amt}`;
      }
      receipt += commands.EOL;
    });
    receipt += commands.HORIZONTAL_LINE.HR1_58MM;
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_RT;
    receipt += 'Sub Total : ' + orderDetails.subTotal;
    receipt += commands.EOL;
    receipt += 'CGST (2.5%): ' + cgst.toFixed(1).toString();
    receipt += commands.EOL;
    receipt += 'SGST (2.5%): ' + sgst.toFixed(1).toString();
    receipt += commands.EOL;
    receipt += 'VAT : ' + vat.toFixed(1).toString();
    receipt += commands.EOL;
    receipt += 'Discount : ' + orderDetails.billDis;
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR1_58MM;
    receipt += commands.EOL;
    receipt += 'Grand Total : RS ' + grandTotal.toFixed(0).toString();
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR1_58MM;
    receipt += commands.EOL;
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_CT;
    receipt += 'Powered by Digipos';
    receipt += commands.EOL;
    receipt += 'www.thedigipos.in';
    receipt += commands.EOL;
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR_THANKS_58MM;
    receipt += commands.EOL;
    receipt += commands.EOL;
    this.flushReceipt(receipt);
  }

  // 3 Inch Order Receipt Service
  async printOrderReceiptFor3inch(orderList, orderDetails) {
    let receipt = '';
    receipt += commands.HARDWARE.HW_INIT;
    receipt += commands.TEXT_FORMAT.TXT_2WIDTH;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_CT;
    receipt += this.restaurantDetails.restaurantName;
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    receipt += this.restaurantDetails.restaurantAddress;
    receipt += commands.EOL;
    receipt += 'Mo - ' + this.restaurantDetails.mobileNumber;
    receipt += commands.EOL;
    receipt += 'GST No. : ' + this.restaurantDetails.gstNumber;
    receipt += commands.EOL;
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR_TEXT_INVOICE_80MM;
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_CT;
    receipt += 'DINE-IN';
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_LT;
    receipt += 'Date : ' + moment(new Date()).format('DD/MM/YYYY hh:mm A');
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_LT;
    receipt += 'Table No : ' + orderDetails.tableName;
    //receipt += commands.TEXT_FORMAT.TXT_ALIGN_RT;
    receipt += '                   Bill No : ' + orderDetails.billNo;
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR1_80MM;
    receipt += commands.EOL;
    receipt += 'Item                         Qty    Price   Amt';
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR1_80MM;
    receipt += commands.EOL;
    // Dynamic Order List Manage
    orderList.forEach((element) => {
      let itemLength: number = 28 - element.itemName.length
      element.quantity = element.quantity.toString();
      element.price = element.price.toString();
      element.total = element.total.toString();
      if (element.itemName.length > 28) {
        let itemsName = element.itemName.match(/.{1,27}/g)
        itemsName.forEach((item, index) => {
          let itemNameLength = 28 - item.length
          if (index == 0) {
            receipt += `${item + Array(itemNameLength > 0 ? itemNameLength : 0).fill('\x20').join('')}${element.quantity.length == 1 ? '   ' + element.quantity : element.quantity.length == 2 ? '  ' + element.quantity : element.quantity.length == 3 ? ' ' + element.quantity : element.quantity}  ${element.price.length == 2 ? '    ' + element.price : element.price.length == 3 ? '   ' + element.price : element.price.length == 4 ? '  ' + element.price : ' ' + element.price} ${element.total.length == 2 ? '    ' + element.total : element.total.length == 3 ? '   ' + element.total : element.total.length == 4 ? '  ' + element.total : ' ' + element.total}`;
          } else {
            receipt += commands.EOL;
            receipt += `${item.trim() + Array(itemNameLength > 0 ? itemNameLength : 0).fill('\x20').join('')}`;
          }
        });
      } else {
        // receipt += `${element.name + Array(itemLength > 0 ? itemLength : 0).fill('\x20').join('')}${element.quantity.length == 1 ? '   ' + element.quantity : element.quantity.length == 2 ? '  ' + element.quantity : element.quantity.length == 3 ? ' ' + element.quantity : element.quantity}  ${element.price.length == 2 ? '    ' + element.price : element.price.length == 3 ? '   ' + element.price : element.price.length == 4 ? '  ' + element.price : ' ' + element.price} ${element.total.length == 2 ? '    ' + element.total : element.total.length == 3 ? '   ' + element.total : element.total.length == 4 ? '  ' + element.total : ' ' + element.total}`;
        receipt += `${element.itemName + Array(itemLength > 0 ? itemLength : 0).fill('\x20').join('')}${element.quantity.length == 1 ? '   ' + element.quantity : element.quantity.length == 2 ? '  ' + element.quantity : element.quantity.length == 3 ? ' ' + element.quantity : element.quantity}  ${element.price.length == 2 ? '    ' + element.price : element.price.length == 3 ? '   ' + element.price : element.price.length == 4 ? '  ' + element.price : ' ' + element.price} ${element.total.length == 2 ? '    ' + element.total : element.total.length == 3 ? '   ' + element.total : element.total.length == 4 ? '  ' + element.total : ' ' + element.total}`;

      }

      receipt += commands.EOL;
    });
    receipt += commands.HORIZONTAL_LINE.HR1_80MM;
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_RT;
    receipt += 'Sub Total : ' + orderDetails.subTotal;
    receipt += commands.EOL;
    receipt += 'CGST (2.5%): ' + orderDetails.totalTax / 2;
    receipt += commands.EOL;
    receipt += 'SGST (2.5%): ' + orderDetails.totalTax / 2;
    receipt += commands.EOL;
    receipt += 'Discount : ' + orderDetails.billDis;
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR1_80MM;
    receipt += commands.EOL;
    receipt += 'Grand Total : ' + orderDetails.grandTotal;
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR1_80MM;
    receipt += commands.EOL;
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_CT;
    receipt += 'Powered by Digipos';
    receipt += commands.EOL;
    receipt += 'www.thedigipos.in';
    receipt += commands.EOL;
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR_THANKS_80MM;
    this.flushReceipt(receipt);
  }

  // 2 Inch KOT Receipt Service
  async printOrderKotReceiptFor2inch(orderList, orderDetails) {
    let receipt = '';
    receipt += commands.HARDWARE.HW_INIT;
    receipt += commands.TEXT_FORMAT.TXT_2WIDTH;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_CT;
    receipt += this.restaurantDetails.restaurantName;
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    receipt += commands.HORIZONTAL_LINE.HR1_58MM;
    receipt += commands.EOL;
    receipt += 'KOT PRINT';
    receipt += commands.EOL;
    receipt += 'DINE IN';
    receipt += commands.EOL;
    receipt += 'Table No : ' + orderDetails;
    receipt += commands.EOL;
    receipt += `KOT No : ${localStorage.getItem('kotnumber')}`;
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR1_58MM;
    receipt += commands.EOL;
    receipt += 'Item                         Qty';
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR1_58MM;
    receipt += commands.EOL;
    // Dynamic Order List Manage
    orderList.forEach((element) => {
      let itemLength: number = 28 - element.itemName.length;
      element.quantity = element.quantity.toString();
      if (element.itemName.length > 28) {
        let itemsName = element.itemName.match(/.{1,27}/g);
        itemsName.forEach((item, index) => {
          let itemNameLength = 28 - item.length;
          if (index == 0) {
            receipt += `${item +
              Array(itemNameLength > 0 ? itemNameLength : 0)
                .fill('\x20')
                .join('')
              }${element.quantity.length == 1
                ? '  ' + element.quantity
                : element.quantity.length == 2
                  ? ' ' + element.quantity
                  : element.quantity
              }`;
          } else {
            receipt += commands.EOL;
            receipt += `${item.trim() +
              Array(itemNameLength > 0 ? itemNameLength : 0)
                .fill('\x20')
                .join('')
              }`;
          }
        });
      } else {
        // receipt += `${itemLength > 0? element.name + Array(itemLength >0?itemLength:0).fill('\x20').join(''):element.name.substring(0, 14) + ' -' +commands.EOL + element.name.substring(14,element.name.length) }${element.qty.length == 1 ? '  ' + element.qty : element.qty.length == 2 ? ' ' + element.qty : element.qty}  ${element.price.length == 2 ? '   ' + element.price : element.price.length == 3 ? '  ' + element.price : ' ' + element.price} ${element.amt.length == 2 ? '   ' + element.amt : element.amt.length == 3 ? '  ' + element.amt : ' ' + element.amt}`;
        receipt += `${element.itemName +
          Array(itemLength > 0 ? itemLength : 0)
            .fill('\x20')
            .join('')
          }${element.quantity.length == 1
            ? '  ' + element.quantity
            : element.quantity.length == 2
              ? ' ' + element.quantity
              : element.quantity
          }`;
      }

      receipt += commands.EOL;
    });
    receipt += commands.HORIZONTAL_LINE.HR1_58MM;
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_RT;
    receipt += moment(new Date()).format('DD/MM/YYYY hh:mm A');
    receipt += commands.EOL;
    receipt += commands.EOL;
    this.flushReceipt(receipt);
  }

  // 3 Inch KOT Receipt Service
  async printOrderKotReceiptFor3inch(orderList, orderDetails) {
    let receipt = '';
    receipt += commands.HARDWARE.HW_INIT;
    receipt += commands.TEXT_FORMAT.TXT_2WIDTH;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_CT;
    receipt += this.restaurantDetails.restaurantName;
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    receipt += commands.HORIZONTAL_LINE.HR1_80MM;
    receipt += commands.EOL;
    receipt += 'KOT PRINT';
    receipt += commands.EOL;
    receipt += 'DINE IN';
    receipt += commands.EOL;
    receipt += 'Table No : ' + orderDetails;
    receipt += commands.EOL;
    receipt += `KOT No : ${localStorage.getItem('kotnumber')}`;
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR1_80MM;
    receipt += commands.EOL;
    receipt += 'Item                                        Qty';
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR1_80MM;
    receipt += commands.EOL;
    // Dynamic Order List Manage
    orderList.forEach((element) => {
      let itemLength: number = 42 - element.itemName.length;
      element.quantity = element.quantity.toString();
      if (element.itemName.length > 42) {
        let itemsName = element.itemName.match(/.{1,27}/g);
        itemsName.forEach((item, index) => {
          let itemNameLength = 42 - item.length;
          if (index == 0) {
            receipt += `${item +
              Array(itemNameLength > 0 ? itemNameLength : 0)
                .fill('\x20')
                .join('')
              }${element.quantity.length == 1
                ? '   ' + element.quantity
                : element.quantity.length == 2
                  ? '  ' + element.quantity
                  : element.quantity.length == 3
                    ? ' ' + element.quantity
                    : element.quantity
              }`;
          } else {
            receipt += commands.EOL;
            receipt += `${item.trim() +
              Array(itemNameLength > 0 ? itemNameLength : 0)
                .fill('\x20')
                .join('')
              }`;
          }
        });
      } else {
        // receipt += `${itemLength > 0? element.name + Array(itemLength >0?itemLength:0).fill('\x20').join(''):element.name.substring(0, 14) + ' -' +commands.EOL + element.name.substring(14,element.name.length) }${element.quantity.length == 1 ? '  ' + element.quantity : element.quantity.length == 2 ? ' ' + element.quantity : element.quantity}  ${element.price.length == 2 ? '   ' + element.price : element.price.length == 3 ? '  ' + element.price : ' ' + element.price} ${element.amt.length == 2 ? '   ' + element.amt : element.amt.length == 3 ? '  ' + element.amt : ' ' + element.amt}`;
        receipt += `${element.itemName +
          Array(itemLength > 0 ? itemLength : 0)
            .fill('\x20')
            .join('')
          }${element.quantity.length == 1
            ? '   ' + element.quantity
            : element.quantity.length == 2
              ? '  ' + element.quantity
              : element.quantity.length == 3
                ? ' ' + element.quantity
                : element.quantity
          }`;
      }

      receipt += commands.EOL;
    });
    receipt += commands.HORIZONTAL_LINE.HR1_80MM;
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_RT;
    receipt += moment(new Date()).format('DD/MM/YYYY hh:mm A');
    receipt += commands.EOL;
    receipt += commands.EOL;
    this.flushReceipt(receipt);
  }

  flushReceipt(receipt) {
    if (this.selectedPrinter !== 'undefined' && this.jspmWSStatus()) {
      // Create a ClientPrintJob
      const cpj = new JSPM.ClientPrintJob();
      // Set Printer type (Refer to the help, there many of them!)
      //https://www.neodynamic.com/Products/Help/JSPrintManager2.0/articles/jsprintmanager.html#client-printer-types
      if (this.isDefaultPrinterSelected) {
        cpj.clientPrinter = new JSPM.DefaultPrinter();
      } else {
        cpj.clientPrinter = new JSPM.InstalledPrinter(this.selectedPrinter);
      }

      cpj.printerCommands = receipt;
      // Send print job to printer!
      cpj.sendToClient();
    } else {
      this.sharedService.errorToast('Printer is not connected');
    }
  }

  async generateKotNumber() {
    let objKotNumber: any = {
      currentDate: '',
      kotNumber: '',
    };

    let kotNumberDetails: any = await this.indexDbService.getDataFromIndexedDB(
      LOCAL_STORAGE_KEYS.KOT_NUMBER
    );

    if (kotNumberDetails) {
      objKotNumber = { ...kotNumberDetails };
    }

    if (objKotNumber.currentDate == moment(new Date()).format('DD/MM/YYYY')) {
      objKotNumber.kotNumber = parseInt(kotNumberDetails.kotNumber) + 1;
      objKotNumber.kotNumber =
        parseInt(objKotNumber.kotNumber) < 10
          ? `0${objKotNumber.kotNumber}`
          : objKotNumber.kotNumber;
    } else {
      objKotNumber.currentDate = moment(new Date()).format('DD/MM/YYYY');
      objKotNumber.kotNumber = '01';
    }

    await this.indexDbService.setDataInIndexedDBWithAwait(
      LOCAL_STORAGE_KEYS.KOT_NUMBER,
      objKotNumber
    );
    return objKotNumber.kotNumber;
  }

  // Testing Print Function

  doTestPrint() {
    this.selectedPrinterDetails = JSON.parse(
      this.indexDbService.getDataFromLocalStorage(
        LOCAL_STORAGE_KEYS.PRINTER_SETTING_DETAILS
      )
    );

    if (this.selectedPrinterDetails) {
      this.selectedPrinter = this.selectedPrinterDetails.printerName;
    }

    if (this.selectedPrinter && this.selectedPrinter !== undefined && this.jspmWSStatus()) {
      // Create a ClientPrintJob
      const cpj = new JSPM.ClientPrintJob();
      // Set Printer type (Refer to the help, there many of them!)
      //https://www.neodynamic.com/Products/Help/JSPrintManager2.0/articles/jsprintmanager.html#client-printer-types
      if (this.isDefaultPrinterSelected) {
        cpj.clientPrinter = new JSPM.DefaultPrinter();
      } else {
        cpj.clientPrinter = new JSPM.InstalledPrinter(this.selectedPrinter);
      }

      // Set content to print...
      //Create Zebra ZPL commands for sample label

      var esc = '\x1B'; //ESC byte in hex notation
      var newLine = '\x0A'; //LF byte in hex notation

      var cmds = esc + '@'; //Initializes the printer (ESC @)
      cmds += esc + '!' + '\x38'; //Emphasized + Double-height + Double-width mode selected (ESC ! (8 + 16 + 32)) 56 dec => 38 hex
      cmds += 'BEST DEAL STORES'; //text to print
      cmds += newLine + newLine;
      cmds += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)
      cmds += 'COOKIES                   5.00';
      cmds += newLine;
      cmds += 'MILK 65 Fl oz             3.78';
      cmds += newLine + newLine;
      cmds += 'SUBTOTAL                  8.78';
      cmds += newLine;
      cmds += 'TAX 5%                    0.44';
      cmds += newLine;
      cmds += 'TOTAL                     9.22';
      cmds += newLine;
      cmds += 'CASH TEND                10.00';
      cmds += newLine;
      cmds += 'CASH DUE                  0.78';
      cmds += newLine + newLine;
      cmds += esc + '!' + '\x18'; //Emphasized + Double-height mode selected (ESC ! (16 + 8)) 24 dec => 18 hex
      cmds += '# ITEMS SOLD 2';
      cmds += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)
      cmds += newLine + newLine;
      cmds += '11/03/13  19:53:17';

      cpj.printerCommands = cmds;
      // Send print job to printer!
      cpj.sendToClient();
    }
  }
}
