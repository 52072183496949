import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { APIURL } from '../global/constant';

@Injectable({
  providedIn: 'root',
})
export class OnlineTableAggragatorService {
  constructor(private commonService: CommonService) {}

  getTableAggragator() {
    return this.commonService.getWithToken(APIURL.tableAggragator);
  }
}
