import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { APIURL } from '../global/constant';
import { CommonService } from './common.service';
import { IndexDbStorageService } from './localstorage.service';
import { LOCAL_STORAGE_KEYS } from '../global/constant';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private commonService: CommonService,
    private indexDbService: IndexDbStorageService,
    private router: Router
  ) {}

  getRestaurantBySyncCode(restaurantSyncCode: string) {
    return this.commonService.get(
      `${APIURL.syncRestaurantDetails}/${restaurantSyncCode}`
    );
  }

  operatorLogin(data) {
    return this.commonService.post(`${APIURL.operatorLogin}`, data);
  }

  async loggedIn() {
    return await this.indexDbService
      .getRestaurantAuthData(LOCAL_STORAGE_KEYS.USER_DETAILS)
      .then((res: any) => {
        if (res) {
          return true;
        } else {
          return false;
        }
      });
  }

  async logout() {
    await this.indexDbService.removeRestaurantAuthDataFromIndexedDB(LOCAL_STORAGE_KEYS.TOKEN);
    await this.indexDbService.removeRestaurantAuthDataFromIndexedDB(LOCAL_STORAGE_KEYS.USER_DETAILS);
    this.indexDbService.removeRestaurantAuthDataFromIndexedDB(LOCAL_STORAGE_KEYS.RESTAURANT_DETAILS);
    // this.indexDbService.clearDataFromIndexedDB();
    localStorage.clear();
    this.router.navigate(['/auth']);
  }
}
