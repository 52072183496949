import { Injectable } from '@angular/core';
import { APIURL } from '../global/constant';
import { CommonService } from './common.service';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  constructor(
    private commonService: CommonService,
    private sharedService: SharedService
  ) {}

  getCategoryList(payload) {
    let param = this.commonService.getParamsFromObj(payload);
    return this.commonService.getWithTokenWithParams(APIURL.category, param);
  }
}
