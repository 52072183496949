import { Injectable } from '@angular/core';
import { APIURL } from '../global/constant';
import { CommonService } from './common.service';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root',
})
export class RestaurantPaymentMethodService {
  constructor(
    private commonService: CommonService,
    private sharedService: SharedService
  ) {}

  getRestaurantPaymentMethodList(payload) {
    let param = this.commonService.getParamsFromObj(payload);
    return this.commonService.getWithTokenWithParams(
      APIURL.restaurantPaymentMode,
      param
    );
  }

  addRestaurantPaymentMethod(postData) {
    return this.commonService.postWithToken(
      APIURL.restaurantPaymentMode,
      postData
    );
  }
}
