import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private readonly baseUrl = environment.apiUrl;

  constructor(private http: HttpClient, @Inject(DOCUMENT) private document: Document) { }

  getHeader() {
    let token = localStorage.getItem('token');
    let headers = new HttpHeaders({
      Authorization: 'Bearer' + ' ' + token,
    });
    return headers;
  }

  //#region API Calling Method With Header Authorization Token

  getWithToken(url: any) {
    return this.http.get(this.baseUrl + url);
  }

  getWithTokenWithParams(url: string, param: HttpParams) {
    return this.http.get(this.baseUrl + url, {
      params: param,
    });
  }

  postWithToken(url: string, data: any) {
    return this.http.post(this.baseUrl + url, data);
  }

  putWithToken(url: string, data: any) {
    return this.http.put(this.baseUrl + url, data, {
      headers: this.getHeader(),
    });
  }

  deleteWithToken(url: string) {
    return this.http.delete(this.baseUrl + url);
  }

  putWithTokenAndParams(url: string, data, param: HttpParams) {
    return this.http.post(this.baseUrl + url, data, {
      params: param,
    });
  }

  //#endregion

  //#region  API Calling Method Without Token

  get(url: string) {
    return this.http.get(this.baseUrl + url);
  }

  postBlob(url: string,param?: HttpParams) {
    return this.http.post(this.baseUrl + url,{}, {
      responseType: 'blob',
      params: param,
    });
  }

  post(url: string, data: any) {
    return this.http.post(this.baseUrl + url, data);
  }
  //#endregion

  //#region Extra Function's

  public getParamsFromObj(obj: any): HttpParams {
    let params = new HttpParams();

    if (obj) {
      Object.keys(obj).forEach((key) => {
        if (obj[key] !== '' && obj[key] !== undefined) {
          params = params.set(key, obj[key]);
        }
      });
    }
    return params;
  }

  //#endregion

  //Disable Right Click Menu.

  disableRightClick() {
    this.document.addEventListener('contextmenu', (event) =>
      event.preventDefault()
    );
  }

  //#endregion
}
