import { Injectable } from '@angular/core';
import { APIURL } from '../global/constant';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class OrderCheckoutService {
  constructor(private commonService: CommonService) {}

  posOrderCheckOut(posOrderDetails: any) {
    return this.commonService.post(APIURL.posOrderCheckOut, posOrderDetails)  
  }
}
