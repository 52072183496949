import { Injectable } from '@angular/core';
import { APIURL } from '../global/constant';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class InventoryService {
  constructor(private commonService: CommonService) {}

  getInventoryReport(payload: any) {
    let param = this.commonService.getParamsFromObj(payload);
    return this.commonService.getWithTokenWithParams(APIURL.inventory, param);
  }

  updateInventory(inventoryDetails: any) {
    return this.commonService.post(APIURL.updateInventory, inventoryDetails);
  }
}
