import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { IndexDbStorageService } from './localstorage.service';
import { SharedService } from './shared.service';
import { LOCAL_STORAGE_KEYS } from '../global/constant';

@Injectable({
  providedIn: 'root',
})
export class OrderResolverService implements Resolve<any> {
  constructor(
    private sharedService: SharedService,
    private indexDbService: IndexDbStorageService
  ) {}

  async resolve(route: ActivatedRouteSnapshot) {
    let tableDataList: any = await this.indexDbService.getDataFromIndexedDB(
      LOCAL_STORAGE_KEYS.POS_TABLES_LIST
    );

    let status = tableDataList.find((x) => {
      return x.id == route.params.id;
    });

    if (!status) {
      this.sharedService.gotoPage('/pos');
      this.sharedService.errorToast('Invalid Table','!OOPS');
    }
  }
}
