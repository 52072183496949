import { Injectable } from '@angular/core';
import * as localforage from 'localforage';
import { BehaviorSubject } from 'rxjs';
import { LOCAL_STORAGE_KEYS } from '../global/constant';
import { OfflineOrderDataService } from './offline.orderdata.service';

@Injectable({
  providedIn: 'root',
})
export class IndexDbStorageService {
  restaurantDBLocalforage: any = localforage;

  getRefreshDataFromIndexDb: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  constructor() {
    this.createRestaurantInstantIndexDb();
    localforage.config({
      driver: [
        localforage.INDEXEDDB,
        localforage.WEBSQL,
        localforage.LOCALSTORAGE,
      ],
      storeName: 'digipos-operator-auth',
      name: 'digipos-operator-auth',
    });
    this.getRefreshDataFromIndexDb.next(true);
  }

  storeItem(key: string, value: string) {
    localforage.setItem(
      key,
      typeof value === 'string' ? value : JSON.stringify(value)
    );
  }

  async createRestaurantInstantIndexDb() {
    const restaurantDetails: any = await this.getRestaurantAuthData(
      LOCAL_STORAGE_KEYS.RESTAURANT_DETAILS
    );
    if (restaurantDetails) {
      this.restaurantDBLocalforage = localforage.createInstance({
        driver: [
          localforage.INDEXEDDB,
          localforage.WEBSQL,
          localforage.LOCALSTORAGE,
        ],
        version: 1.0,
        storeName: `digipos-${restaurantDetails.restaurantSyncCode}`,
        name: `digipos-${restaurantDetails.restaurantSyncCode}`,
      });
    }
  }

  readStorage(key: string) {
    return localforage.getItem(key);
  }

  removeStorage(key: string) {
    localforage.removeItem(key);
  }

  clearStorage() {
    localforage.clear();
  }

  //#region Digipos Authenticaton

  getRestaurantAuthData(key) {
    return new Promise((resolve, reject) => {
      localforage
        .getItem(key)
        .then((result: any) => {
          resolve(JSON.parse(result));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  setRestaurantAuthData(key, value) {
    return localforage
      .setItem(key, JSON.stringify(value))
      .then(() => { })
      .catch(() => { });
  }

  removeRestaurantAuthDataFromIndexedDB(key) {
    return new Promise((resolve, reject) => {
      localforage
        .removeItem(key)
        .then((result: any) => {
          const remove = 'Key Removed';
          return resolve(remove);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  //#endregion

  // store particular key details
  setDataInIndexedDB(key, value) {
    return this.restaurantDBLocalforage
      .setItem(key, JSON.stringify(value))
      .then(() => { })
      .catch(() => { });
  }

  setDataInIndexedDBWithAwait(key, value) {
    return this.restaurantDBLocalforage.setItem(key, JSON.stringify(value));
  }

  // fetch particular key details
  async getDataFromIndexedDB(key) {
    return new Promise((resolve, reject) => {
      this.restaurantDBLocalforage
        .getItem(key)
        .then((result: any) => {
          resolve(JSON.parse(result));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  // For Remove Particular Field/Key
  removeDataFromIndexedDB(key) {
    return new Promise((resolve, reject) => {
      this.restaurantDBLocalforage
        .removeItem(key)
        .then((result: any) => {
          const remove = 'Key Removed';
          return resolve(remove);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  // Database has been entirely deleted.
  clearDataFromIndexedDB() {
    return localforage.clear();
  }

  //#region Localstorage Service

  getDataFromLocalStorage(key) {
    return localStorage.getItem(key);
  }

  setArrayDataInLocalStorage(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  setDataInLocalStorage(key, data) {
    localStorage.setItem(key, data);
  }

  removeDataFromLocalStorage(key) {
    localStorage.removeItem(key);
  }

  //#endregion
}
